import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import {
	UploadStatusStatus,
	UploadStatusesParams,
	uploadStatusFieldsFragment,
} from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const uploadStatusDocument = graphql(/* GraphQL */ `
	query uploadStatus($params: UploadStatusParams!) {
		uploadStatus(params: $params) {
			...uploadStatusFields
		}
	}
`);

interface Args {
	params: UploadStatusesParams;
	userId?: string;
}

export const UploadStatusPollInterval = 1300;
const UploadStatusPollIntervalLong = 10000;

function uploadStatus({ params, userId }: Args) {
	return client(userId)
		.request(uploadStatusDocument, { params })
		.then((res) => res.uploadStatus);
}

export function useUploadStatus(
	params: UploadStatusesParams,
	options?: {
		onSettled?: (status: uploadStatusFieldsFragment | undefined) => void;
	}
) {
	const { userId, _id } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "uploadStatus", params],
		() => uploadStatus({ params, userId }),
		{
			onSettled: (data) => {
				options?.onSettled?.(data ?? undefined);
			},
			refetchInterval: (data) => {
				switch (data?.status) {
					case UploadStatusStatus.PREUPLOAD:
						return false;

					case UploadStatusStatus.UPLOADING:
					case UploadStatusStatus.PENDING:
					case UploadStatusStatus.PROCESSING: {
						let interval = UploadStatusPollInterval;

						if (
							data.progress.total > 200 &&
							data.progress.percent > 0.1 &&
							data.progress.percent < 0.9
						) {
							interval = UploadStatusPollIntervalLong;
						}

						return interval;
					}

					default:
						return false;
				}
			},
		}
	);
}
