import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { listAccountMembersQueryKey } from "api/queries/useListAccountMembers";
import { graphql } from "gql/gql";
import { SendAccountInvitationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const sendAccountInvitationDocument = graphql(/* GraphQL */ `
	mutation sendAccountInvitation($params: SendAccountInvitationParams!) {
		sendAccountInvitation(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: SendAccountInvitationParams;
	userId?: string;
}

const sendAccountInvitation = ({ userId, params }: Args) =>
	client(userId)
		.request(sendAccountInvitationDocument, { params })
		.then((res) => res.sendAccountInvitation);

const useSendAccountInvitation = () => {
	const queryClient = useQueryClient();
	const { _id, userId } = useActiveAccount();

	const accountMembersQueryKey = listAccountMembersQueryKey(userId, _id);

	return useMutation(
		(params: SendAccountInvitationParams) =>
			sendAccountInvitation({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(accountMembersQueryKey);
			},
		}
	);
};

export default useSendAccountInvitation;
