import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateProximityAssetsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createProximityAssetsDocument = graphql(/* GraphQL */ `
	mutation createProximityAssets($params: CreateProximityAssetsParams!) {
		createProximityAssets(params: $params) {
			error {
				code
				message
			}
			assets {
				asset_set_id
			}
			success
		}
	}
`);

interface Args {
	params: CreateProximityAssetsParams;
	userId?: string;
}

const createProximityAssets = ({ params, userId }: Args) =>
	client(userId)
		.request(createProximityAssetsDocument, { params })
		.then((res) => res.createProximityAssets);

const useCreateProximityAssets = () => {
	const { userId, _id } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateProximityAssetsParams) =>
			createProximityAssets({ params, userId }),
		{
			onSettled: () => {
				/** clear all data from any listProximityAssets query caches that include this asset_set_ids because if people remove the same amount of assets as they add, then they won't be re-queried otherwise */
				queryClient.removeQueries([
					"user",
					userId,
					"account",
					_id,
					"proximityAssets",
				]);

				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"proximityAssetSets",
				]);
			},
		}
	);
};

export default useCreateProximityAssets;
