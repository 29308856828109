import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetProximityAssetSetParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const getProximityAssetSetDocument = graphql(/* GraphQL */ `
	query getProximityAssetSet($params: GetProximityAssetSetParams!) {
		getProximityAssetSet(params: $params) {
			_id
			gid
			account_id
			asset_count
			created
			name
			proximity
			updated
		}
	}
`);

interface Args {
	params: GetProximityAssetSetParams;
	userId?: string;
}

const getProximityAssetSet = ({ params, userId }: Args) => {
	return client(userId)
		.request(getProximityAssetSetDocument, { params })
		.then((res) => res.getProximityAssetSet);
};

export const useGetProximityAssetSet = (params: GetProximityAssetSetParams) => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	const queryKey = getProximityAssetSetQueryKey(userId, _id, params);

	return useQuery(queryKey, () => getProximityAssetSet({ params, userId }), {
		enabled: !!userId && !!params._id,
		onError: (error) => handleError(error),
	});
};

export const getProximityAssetSetQueryKey = (
	userId: string | undefined,
	_id: string | undefined,
	params: GetProximityAssetSetParams
) => {
	return ["user", userId, "account", _id, "proximityAssetSets", [params._id]];
};
