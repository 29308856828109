import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { streamIdPlaceholder } from "common/apiDefinitions";
import { graphql } from "gql/gql";
import { GetStreamParams, getStreamQuery } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const getStreamDocument = graphql(/* GraphQL */ `
	query getStream($params: GetStreamParams!) {
		getStream(params: $params) {
			...streamFields
		}
	}
`);

interface Args {
	params: GetStreamParams;
	userId?: string;
}

const getStream = ({ params, userId }: Args) => {
	return client(userId)
		.request(getStreamDocument, { params })
		.then((res) => res.getStream);
};

const useGetStream = (
	params: GetStreamParams,
	options?: { onSuccess?: (data: getStreamQuery["getStream"]) => void }
) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id, "stream", params._id],
		() => getStream({ params, userId }),
		{
			enabled: !!userId && !!params._id && params._id !== streamIdPlaceholder,
			onError: (error) => handleError(error),
			onSuccess: options?.onSuccess,
		}
	);
};

export default useGetStream;
