import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ShareIncidentParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const shareIncidentDocument = graphql(/* GraphQL */ `
	mutation shareIncident($params: ShareIncidentParams!) {
		shareIncident(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: ShareIncidentParams;
	userId?: string;
}

const shareIncident = ({ params, userId }: Args) =>
	client(userId)
		.request(shareIncidentDocument, { params })
		.then((res) => res.shareIncident);

export function useShareIncident() {
	const { userId } = useActiveAccount();

	return useMutation(({ params }: Args) => shareIncident({ params, userId }));
}
