import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListProximityAssetsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listProximityAssetsDocument = graphql(/* GraphQL */ `
	query listProximityAssets($params: ListProximityAssetsParams!) {
		listProximityAssets(params: $params) {
			...proximityAssetFields
		}
	}
`);

interface Args {
	params: ListProximityAssetsParams;
	userId?: string;
}

export const listProximityAssets = ({ params, userId }: Args) => {
	/**
	 * lat and lng can come in outside of the range of actual lat and lngs
	 * running them through new google.maps.LatLng() will fix this
	 * it caps off lat outside 90 to -90
	 * it rolls over lngs that are outside 180 to -180
	 * magic
	 */
	const sanitizedLatLng =
		params.lat && params.lng ?
			new google.maps.LatLng(params.lat, params.lng)
		:	{ lat: () => undefined, lng: () => undefined };

	const sanitizedParams = {
		...params,
		lat: sanitizedLatLng.lat(),
		lng: sanitizedLatLng.lng(),
	};

	return client(userId)
		.request(listProximityAssetsDocument, { params: sanitizedParams })
		.then((res) => res.listProximityAssets);
};

const useListProximityAssets = (params: ListProximityAssetsParams) => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	/** injecting a requirement to pass asset_set_ids in query params because the backend accepts [] and then errors */
	const hasValidParams =
		params.asset_set_ids !== undefined &&
		(params.asset_set_ids?.length ?? 0) > 0 &&
		(params.count ?? 0) > 0;

	const proximityAssetsQueryKey = listProximityAssetsQueryKey(
		userId,
		_id,
		params
	);

	return useQuery(
		proximityAssetsQueryKey,
		() => listProximityAssets({ params, userId }),
		{
			enabled: !!userId && hasValidParams,
			onError: (error) => handleError(error),
			refetchOnMount: false,
			refetchOnReconnect: false,
		}
	);
};

export default useListProximityAssets;

export const listProximityAssetsQueryKey = (
	userId: string | undefined,
	_id: string | undefined,
	params: ListProximityAssetsParams
) => {
	return ["user", userId, "account", _id, "proximityAssets", params];
};
