import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SendAccountInvitationsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const sendAccountInvitationsDocument = graphql(/* GraphQL */ `
	mutation sendAccountInvitations($params: SendAccountInvitationsParams!) {
		sendAccountInvitations(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: SendAccountInvitationsParams;
	userId?: string;
}

const sendAccountInvitations = ({ userId, params }: Args) =>
	client(userId)
		.request(sendAccountInvitationsDocument, { params })
		.then((res) => res.sendAccountInvitations);

const useSendAccountInvitations = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: SendAccountInvitationsParams) =>
			sendAccountInvitations({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(["accounts", _id, "members"]);
			},
		}
	);
};

export default useSendAccountInvitations;
