import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ResendAccountInvitationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const resendAccountInvitationDocument = graphql(/* GraphQL */ `
	mutation resendAccountInvitation($params: ResendAccountInvitationParams!) {
		resendAccountInvitation(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: ResendAccountInvitationParams;
	userId?: string;
}

const resendAccountInvitation = ({ userId, params }: Args) =>
	client(userId)
		.request(resendAccountInvitationDocument, { params })
		.then((res) => res.resendAccountInvitation);

const useResendAccountInvitation = () => {
	const { userId } = useActiveAccount();

	return useMutation((params: ResendAccountInvitationParams) =>
		resendAccountInvitation({ params, userId })
	);
};

export default useResendAccountInvitation;
