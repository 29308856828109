import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { UploadCompleteParams } from "gql/graphql";

const uploadCompleteDocument = graphql(/* GraphQL */ `
	mutation uploadComplete($params: UploadCompleteParams!) {
		uploadComplete(params: $params) {
			error {
				code
				message
			}
			success
			upload_status {
				...uploadStatusFields
			}
		}
	}
`);

interface Args {
	params: UploadCompleteParams;
	userId?: string;
}

// This is exposing the raw graphql request rather than a hook as we don't need
// it in a react context.
export function uploadComplete({ params, userId }: Args) {
	return client(userId)
		.request(uploadCompleteDocument, { params })
		.then((res) => res.uploadComplete);
}
