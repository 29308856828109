import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListResolverWebhooksParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listResolverWebhooksDocument = graphql(/* GraphQL */ `
	query listResolverWebhooks($params: ListResolverWebhooksParams!) {
		listResolverWebhooks(params: $params) {
			_id
			account_id
			name
			url
			created
			updated
			user_id
			disabled
		}
	}
`);

interface Args {
	params: ListResolverWebhooksParams;
	userId?: string;
}

const listResolverWebhooks = ({ userId, params }: Args) =>
	client(userId)
		.request(listResolverWebhooksDocument, { params })
		.then((res) => res.listResolverWebhooks);

const useListResolverWebhooks = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	const params = {};

	return useQuery(
		["user", userId, "account", _id, "resolverWebhooks"],
		() => listResolverWebhooks({ params, userId }),
		{
			enabled: !!userId,
			onError: (error) => handleError(error),
		}
	);
};

export default useListResolverWebhooks;
