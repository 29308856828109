import { useRollbar } from "@rollbar/react";
import {
	MutationCache,
	QueryCache,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import Rollbar from "rollbar";
import { PartialDeep } from "type-fest";
import { isObjWithError } from "utils/errorHelpers";
import { getNormalizedErrorMessage } from "utils/getNormalizedErrorMessage";
import logErrors from "utils/logErrors";

export function ReactQueryProvider({ children }: PropsWithChildren<{}>) {
	const rollbar = useRollbar();
	const queryClient = getQueryClient(rollbar);
	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	);
}

/** query client init */
function getQueryClient(rollbar: Rollbar) {
	return new QueryClient({
		defaultOptions: {
			queries: {
				/** we're not worried about stale data when a user doesn't have the app open for most queries */
				refetchOnWindowFocus: false,
				retry: false,
				/** 0 is default, but just declaring it here. All queries don't use caching unless we add a new staleTime to the specific query */
				staleTime: 0,
			},
		},

		logger: {
			/** silence built in console errors for react-query because we handle them ourselves in the handleError function of useSnackbar */
			error: () => void 0,
			log: console.log,
			warn: console.warn,
		},

		mutationCache: new MutationCache({
			onError: (err) => {
				/** always log mutation errors */
				logErrors(err);

				const errorMessage = getNormalizedErrorMessage(err);

				rollbar.error(new Error(errorMessage), {
					error: err,
				});
			},
			onSuccess: (data) => {
				if (isObjWithError(data) && data.error != undefined) {
					/** by default we log mutation errors that come back as 200 responses with error in the data */
					logErrors(data.error);

					const errorMessage = getNormalizedErrorMessage(data.error);

					rollbar.error(new Error(errorMessage), {
						error: data.error,
					});
				}
			},
		}),

		queryCache: new QueryCache({
			/** correctly ignored: we won't know how the error is coming in */
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			onError: (errorAny: any) => {
				/** always log query errors */
				logErrors(errorAny);

				const error = errorAny as PartialDeep<{
					request: {
						responseURL: string;
					};
					response: {
						errors: {
							message: string;
							path: string[];
						}[];
						status: number;
					};
				}>;

				const mfaRequiredError = error?.response?.errors?.some(
					(error) => error.message === "mfa_required"
				);

				if (mfaRequiredError) {
					window.location.replace("/authenticate");
				} else if (isUnauthenticatedError(errorAny)) {
					if (!isAuthRoute()) {
						getQueryClient(rollbar).clear();
						window.location.replace("/unauthorized");
					}
				} else {
					rollbar.error(new Error(errorAny));
				}
			},
		}),
	});
}

function isAuthRoute(): boolean {
	/**
	 * If the getPerson query fails on one of these routes,
	 * that's okay because there shouldn't be a person yet
	 */
	if (
		window.location.pathname === "/" ||
		window.location.pathname.includes("signin") ||
		window.location.pathname.includes("signup") ||
		window.location.pathname.includes("reset_password") ||
		window.location.pathname.includes("change_email") ||
		window.location.pathname.includes("unauthorized") ||
		window.location.pathname.includes("irn")
	) {
		return true;
	}
	return false;
}

function isUnauthenticatedError(error: unknown): boolean {
	if (
		error &&
		typeof error === "object" &&
		"response" in error &&
		error.response &&
		typeof error.response === "object"
	) {
		if (
			"errors" in error.response &&
			error.response.errors &&
			error.response.errors instanceof Array
		) {
			return error.response.errors.some(
				(err) =>
					typeof err === "object" &&
					"message" in err &&
					err.message === "unauthenticated"
			);
		}

		if ("status" in error.response && error?.response?.status === 401) {
			return true;
		}
	}

	return false;
}
