import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const createMFACodeDocument = graphql(/* GraphQL */ `
	mutation createMFACode {
		createMFACode {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	controller?: AbortController;
	userId?: string;
}

const createMFACode = ({ controller, userId }: Args) =>
	client(userId, controller)
		.request(createMFACodeDocument)
		.then((res) => res.createMFACode);

const useCreateMFACode = () => {
	const { userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useMutation(
		(controller: AbortController) => createMFACode({ controller, userId }),
		{
			onSuccess: (data) => {
				if (data?.error) {
					handleError(data.error);
				}
			},
		}
	);
};

export default useCreateMFACode;
