import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateIncidentFlagParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createIncidentFlagDocument = graphql(/* GraphQL */ `
	mutation createIncidentFlag($params: CreateIncidentFlagParams!) {
		createIncidentFlag(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateIncidentFlagParams;
	userId?: string;
}

const createIncidentFlag = ({ params, userId }: Args) =>
	client(userId)
		.request(createIncidentFlagDocument, { params })
		.then((res) => res.createIncidentFlag);

const useCreateIncidentFlag = () => {
	const { userId } = useActiveAccount();

	return useMutation(({ params }: Args) =>
		createIncidentFlag({ params, userId })
	);
};

export default useCreateIncidentFlag;
