import { GraphQLClient } from "graphql-request";

const getClient = (userId?: string, controller?: AbortController) => {
	const endpoint = window.SAM._env_.REACT_APP_BASE_GRAPHQL_URL;

	const client = new GraphQLClient(endpoint, {
		credentials: "include",
		headers: {
			["SAM-Context"]: window.btoa(JSON.stringify({ user_id: userId })),
			["X-Requested-With"]: "XMLHttpRequest",
		},
		mode: "cors",
		signal: controller?.signal,
	});

	return client;
};

export default getClient;
