import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListSlackWebhooksParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listSlackWebhooksDocument = graphql(/* GraphQL */ `
	query listSlackWebhooks($params: ListSlackWebhooksParams!) {
		listSlackWebhooks(params: $params) {
			_id
			account_id
			user_id
			team_name
			channel_name
			configuration_url
			disabled
			created
			updated
		}
	}
`);

interface Args {
	params: ListSlackWebhooksParams;
	userId?: string;
}

const listSlackWebhooks = ({ userId, params }: Args) =>
	client(userId)
		.request(listSlackWebhooksDocument, { params })
		.then((res) => res.listSlackWebhooks);

const useListSlackWebhooks = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	const params = {};

	return useQuery(
		["user", userId, "account", _id, "slackWebhooks"],
		() => listSlackWebhooks({ params, userId }),
		{
			enabled: !!userId,
			onError: (error) => handleError(error),
		}
	);
};

export default useListSlackWebhooks;
