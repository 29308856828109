import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { UpdateProximityAssetSetParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const updateProximityAssetSetDocument = graphql(/* GraphQL */ `
	mutation updateProximityAssetSet($params: UpdateProximityAssetSetParams!) {
		updateProximityAssetSet(params: $params) {
			error {
				code
				message
			}
			asset_set {
				_id
			}
			success
		}
	}
`);

interface Args {
	params: UpdateProximityAssetSetParams;
	userId?: string;
}

const updateProximityAssetSet = ({ userId, params }: Args) =>
	client(userId)
		.request(updateProximityAssetSetDocument, { params })
		.then((res) => res.updateProximityAssetSet);

const useUpdateProximityAssetSet = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError, handleSuccess } = useSnackbar();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdateProximityAssetSetParams) =>
			updateProximityAssetSet({ params, userId }),
		{
			onSettled: (data) => {
				queryClient.invalidateQueries(
					["user", userId, "account", _id, "proximityAssetSets"],
					{ exact: true }
				);
				queryClient.invalidateQueries(
					[
						"user",
						userId,
						"account",
						_id,
						"proximityAssetSets",
						[data?.asset_set?._id],
					],
					{ exact: true }
				);
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				} else if (data.success) {
					handleSuccess("Your asset collection has been saved.");
				}
			},
		}
	);
};

export default useUpdateProximityAssetSet;
