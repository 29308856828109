import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getDashboardQueryKey } from "api/queries/useGetDashboard";
import { Dashboard, Tab } from "autoGenSubTypes";
import { graphql } from "gql/gql";
import { CreateDashboardTabParams } from "gql/graphql";
import { tabTypeIndex } from "utils/tabUtils";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const createDashboardTabDocument = graphql(/* GraphQL */ `
	mutation createDashboardTab($params: CreateDashboardTabParams!) {
		createDashboardTab(params: $params) {
			error {
				code
				message
			}
			success
			tab {
				__typename
				key
				type
				... on IncidentTab {
					incident_tab_data {
						incident_id
					}
				}
			}
		}
	}
`);

interface Args {
	params: CreateDashboardTabParams;
	userId?: string;
}

const createDashboardTab = ({ userId, params }: Args) =>
	client(userId)
		.request(createDashboardTabDocument, { params })
		.then((res) => res.createDashboardTab);

const useCreateDashboardTab = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const queryClient = useQueryClient();
	const dashboardQueryKey = getDashboardQueryKey(userId, _id);

	return useMutation(
		({ params }: Args) => createDashboardTab({ params, userId }),
		{
			onMutate: async (variables) => {
				await queryClient.cancelQueries(dashboardQueryKey, { exact: true });

				const dashboardSnapshot = queryClient.getQueryData<Dashboard>(
					dashboardQueryKey,
					{ exact: true }
				);

				/** Create a temporary (dummy) tab as a placeholder. The incorrect properties (i.e. the key) will be overwritten onSettle once the cache is invalidated  */
				const tempTabKey = Math.random().toString(36).slice(2);
				const tempNewTab = {
					...variables.params.tab,
					__typename: tabTypeIndex[variables.params.tab.type],
					key: tempTabKey,
				} as Tab;

				/** these are optional params for creating a dashboard, but we need to set them with defaults if we're going to optimistically create a tab */
				if (tempNewTab.__typename === "AlertsTab") {
					tempNewTab.alerts_tab_data.bottom_sound = {
						enabled: false,
						name: "",
					};

					tempNewTab.alerts_tab_data.top_sound = {
						enabled: false,
						name: "",
					};
				}

				queryClient.setQueryData<Dashboard>(
					dashboardQueryKey,
					(oldData) =>
						oldData && { ...oldData, tabs: [...oldData.tabs, tempNewTab] }
				);

				return { dashboardSnapshot };
			},
			onSettled: (data, error, variables, context) => {
				if ((data?.error || error) && context?.dashboardSnapshot) {
					return queryClient.setQueryData(
						dashboardQueryKey,
						context.dashboardSnapshot
					);
				}

				queryClient.invalidateQueries(dashboardQueryKey, { exact: true });
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				}
			},
		}
	);
};

export default useCreateDashboardTab;
