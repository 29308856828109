import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getDashboardQueryKey } from "api/queries/useGetDashboard";
import { graphql } from "gql/gql";
import { DeleteStreamParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const deleteStreamDocument = graphql(/* GraphQL */ `
	mutation deleteStream($params: DeleteStreamParams!) {
		deleteStream(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: DeleteStreamParams;
	userId?: string;
}

const deleteStream = ({ userId, params }: Args) =>
	client(userId)
		.request(deleteStreamDocument, { params })
		.then((res) => res.deleteStream);

const useDeleteStream = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: DeleteStreamParams) => deleteStream({ params, userId }),
		{
			onSettled: () => {
				/** invalidate every streams query in case it includes the new stream */
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"streams",
				]);
				/** invalidate dashboard cached because it could have this streamId in it) */
				queryClient.invalidateQueries(getDashboardQueryKey(userId, _id));
			},
		}
	);
};

export default useDeleteStream;
