import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { listStreamsQueryKey } from "api/queries/useListStreams";
import { graphql } from "gql/gql";
import { CopyStreamParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const copyStreamDocument = graphql(/* GraphQL */ `
	mutation copyStream($params: CopyStreamParams!) {
		copyStream(params: $params) {
			error {
				code
				message
			}
			success
			stream {
				_id
				gid
			}
		}
	}
`);

interface Args {
	params: CopyStreamParams;
	userId?: string;
}

const copyStream = ({ params, userId }: Args) =>
	client(userId)
		.request(copyStreamDocument, { params })
		.then((res) => res.copyStream);

export const useCopyStream = () => {
	const { userId, _id: accountId } = useActiveAccount();

	const queryClient = useQueryClient();

	return useMutation(
		(params: CopyStreamParams) => copyStream({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(listStreamsQueryKey(userId, accountId));
			},
		}
	);
};
