import { ApolloClient, createHttpLink } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { getActiveUserIndex } from "utils/getActiveUserIndex";
import { getApolloCache } from "./getApolloCache";

if (window.SAM._env_.REACT_APP_ENVIRONMENT === "local") {
	// Adds messages only in a dev environment
	loadDevMessages();
	loadErrorMessages();
}

const httpLink = createHttpLink({
	uri: window.SAM._env_.REACT_APP_BASE_GRAPHQL_URL,
	credentials: "include",
});

const samContextLink = setContext((_, { headers }) => {
	return {
		headers: {
			...headers,
			["SAM-Context"]: window.btoa(
				JSON.stringify({ user_index: getActiveUserIndex() })
			),
		},
	};
});

export const apolloClient = new ApolloClient({
	link: samContextLink.concat(httpLink),
	cache: getApolloCache(),
});
