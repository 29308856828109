import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListLocalAccountsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const listLocalAccountsDocument = graphql(/* GraphQL */ `
	query listLocalAccounts($params: ListLocalAccountsParams!) {
		listLocalAccounts(params: $params) {
			alt_names {
				type
				value
			}
			concepts
			name
		}
	}
`);

interface Args {
	params: ListLocalAccountsParams;
	userId?: string;
}

const listLocalAccounts = ({ userId, params }: Args) =>
	client(userId)
		.request(listLocalAccountsDocument, { params })
		.then((res) => res.listLocalAccounts);

const useListLocalAccounts = (params: ListLocalAccountsParams) => {
	const { userId, _id } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "localAccounts", params],
		() => listLocalAccounts({ params, userId }),
		{
			cacheTime: 24 * 60 * 60 * 1000,
			enabled: !!userId && !!params.longkey,
			staleTime: 24 * 60 * 60 * 1000,
		}
	);
};

export default useListLocalAccounts;
