import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { UpdateAccountMemberParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const updateAccountMemberDocument = graphql(/* GraphQL */ `
	mutation updateAccountMember($params: UpdateAccountMemberParams!) {
		updateAccountMember(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: UpdateAccountMemberParams;
	userId?: string;
}

const UpdateAccountMember = ({ userId, params }: Args) =>
	client(userId)
		.request(updateAccountMemberDocument, { params })
		.then((res) => res.updateAccountMember);

const useUpdateAccountMember = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError, handleSuccess } = useSnackbar();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdateAccountMemberParams) =>
			UpdateAccountMember({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(
					["user", userId, "account", _id, "listAccountMembers"],
					{ exact: true }
				);
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				} else if (data.success) {
					handleSuccess("Account member has been updated.");
				}
			},
		}
	);
};

export default useUpdateAccountMember;
