import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listLanguagesDocument = graphql(/* GraphQL */ `
	query listLanguages {
		listLanguages {
			key
			name
		}
	}
`);

interface Args {
	userId?: string;
}

const listLanguages = ({ userId }: Args) =>
	client(userId)
		.request(listLanguagesDocument)
		.then((res) => res.listLanguages);

const useListLanguages = () => {
	const { userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "languages"],
		() => listLanguages({ userId }),
		{
			cacheTime: 24 * 60 * 60 * 1000,
			enabled: !!userId,
			onError: (error) => handleError(error),
			staleTime: 24 * 60 * 60 * 1000,
		}
	);
};

export default useListLanguages;
