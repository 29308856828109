import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { UpdateProximityAssetParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";
import { listProximityAssetsQueryKey } from "api/queries/useListProximityAssets";

const updateProximityAssetDocument = graphql(/* GraphQL */ `
	mutation updateProximityAsset($params: UpdateProximityAssetParams!) {
		updateProximityAsset(params: $params) {
			error {
				code
				message
			}
			asset {
				_id
			}
			success
		}
	}
`);

interface Args {
	params: UpdateProximityAssetParams;
	userId?: string;
}

const updateProximityAsset = ({ userId, params }: Args) =>
	client(userId)
		.request(updateProximityAssetDocument, { params })
		.then((res) => res.updateProximityAsset);

const useUpdateProximityAsset = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError, handleSuccess } = useSnackbar();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdateProximityAssetParams) =>
			updateProximityAsset({ params, userId }),
		{
			onSettled: (data) => {
				if (data?.asset) {
					queryClient.invalidateQueries(
						listProximityAssetsQueryKey(userId, _id, { _ids: [data.asset._id] })
					);
					queryClient.removeQueries([
						"user",
						userId,
						"account",
						_id,
						"proximityAssets",
					]);
				}
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				} else if (data.success) {
					handleSuccess("Your asset collection has been saved.");
				}
			},
		}
	);
};

export default useUpdateProximityAsset;
