import { useInfiniteQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListPostsForIncidentParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listPostsForIncidentDocument = graphql(/* GraphQL */ `
	query ListPostsForIncident($params: ListPostsForIncidentParams!) {
		listPostsForIncident(params: $params) {
			__typename
			_id
			author {
				avatar_url
				handle
				name
				permalink
				verified
			}
			created_at
			language
			medias {
				large_url
				small_url
				type
				video_url
			}
			original_subtext
			original_text
			permalink
			social_type
			source_label_categories
			subtext
			text
		}
	}
`);

interface Args {
	pageParam: string | undefined;
	params: ListPostsForIncidentParams;
	userId?: string;
}

const listPostsForIncident = ({ pageParam, params, userId }: Args) => {
	const updatedParams = { ...params };
	/** in the backend, the id and sort information are used to find the last created date and return the next posts in the correct order */
	updatedParams.min_id = pageParam;
	updatedParams.count = 25;

	return client(userId)
		.request(listPostsForIncidentDocument, { params: updatedParams })
		.then((res) => res.listPostsForIncident);
};

const useListPostsForIncident = (params: ListPostsForIncidentParams) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useInfiniteQuery(
		["user", userId, "account", _id, "search", params],
		({ pageParam }: { pageParam?: string | undefined }) =>
			listPostsForIncident({ pageParam, params, userId }),
		{
			enabled:
				!!userId &&
				!!params.incident_id &&
				params.source_label_categories !== null,
			getNextPageParam: (lastPage): string | undefined => {
				if (lastPage.length === 0) return;
				return lastPage[lastPage.length - 1]._id;
			},
			onError: (error) => handleError(error),
			refetchInterval: 30 * 1000,
		}
	);
};

export default useListPostsForIncident;
