import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { createGetEverbridgeIntegrationQueryKey } from "api/queries/useGetEverbridgeIntegration";
import { createListEverbridgeIntegrationsQueryKey } from "api/queries/useListEverbridgeIntegrations";
import { graphql } from "gql/gql";
import { UpdateEverbridgeIntegrationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const updateEverbridgeIntegrationDocument = graphql(/* GraphQL */ `
	mutation updateEverbridgeIntegration(
		$params: UpdateEverbridgeIntegrationParams!
	) {
		updateEverbridgeIntegration(params: $params) {
			error {
				code
				message
			}
			everbridge_integration {
				_id
			}
			success
		}
	}
`);

interface Args {
	params: UpdateEverbridgeIntegrationParams;
	userId?: string;
}

const updateEverbridgeIntegration = ({ userId, params }: Args) =>
	client(userId)
		.request(updateEverbridgeIntegrationDocument, { params })
		.then((res) => res.updateEverbridgeIntegration);

const useUpdateEverbridgeIntegration = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdateEverbridgeIntegrationParams) =>
			updateEverbridgeIntegration({ params, userId }),
		{
			onSettled: (data, error, variables) => {
				const listEverbridgeIntegrationsQueryKey =
					createListEverbridgeIntegrationsQueryKey(userId, _id);
				queryClient.invalidateQueries(listEverbridgeIntegrationsQueryKey);

				const getEverbridgeIntegrationQueryKey =
					createGetEverbridgeIntegrationQueryKey(userId, _id, {
						_id: variables._id,
					});
				queryClient.invalidateQueries(getEverbridgeIntegrationQueryKey);
			},
		}
	);
};

export default useUpdateEverbridgeIntegration;
