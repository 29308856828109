import { useRollbar } from "@rollbar/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { earliestPossibleQueryDate } from "common/apiDefinitions";
import { graphql } from "gql/gql";
import { SearchIncidentsParams } from "gql/graphql";
import getOldestDetectedDate from "utils/getOldestDetectedDate";
import logErrors from "utils/logErrors";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const searchIncidentsResultsDocument = graphql(/* GraphQL */ `
	query searchIncidentsResults($params: SearchIncidentsParams!) {
		searchIncidentsResults(params: $params) {
			results {
				highlight {
					source
					text
				}
				incident {
					_id
					gid
					detected_date
					headline {
						source
						text
						type
					}
					severity
					types
					wheres {
						compact_display
					}
				}
			}
			total_count {
				value
				relation
			}
		}
	}
`);

interface Args {
	pageParam: null | string | undefined;
	params: SearchIncidentsParams;
	userId?: string;
}

const searchIncidentsResults = ({ pageParam, params, userId }: Args) => {
	const updatedParams = { ...params };

	const before = params.before ?? new Date().toISOString();
	updatedParams.before = pageParam ? new Date(pageParam).toISOString() : before;

	return client(userId)
		.request(searchIncidentsResultsDocument, { params: updatedParams })
		.then((res) => res.searchIncidentsResults);
};

const useSearchIncidentsResults = (params: SearchIncidentsParams) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();
	const rollbar = useRollbar();

	return useInfiniteQuery(
		["user", userId, "account", _id, "searchIncidentsResults", params],
		({ pageParam }: { pageParam?: null | string | undefined }) =>
			searchIncidentsResults({ pageParam, params, userId }),
		{
			cacheTime: 0,
			enabled: !!_id && !!(params.locations && params.locations.length > 0),
			getNextPageParam: (lastPage): number | undefined => {
				if (lastPage.results.length === 0) return;

				const oldestDetectedDate = getOldestDetectedDate(lastPage.results);
				if (!oldestDetectedDate) {
					handleError("We are unable to load more incidents at this time");
					logErrors(
						`No detected_date found in latest search results: ${JSON.stringify(
							lastPage
						)}`,
						"src/api/queries/useSearchIncidents.tsx"
					);

					/** log to Rollbar */
					rollbar.error(
						new Error(
							`No detected_date found in latest search results: ${JSON.stringify(
								lastPage
							)}`
						)
					);

					return;
				}
				// can't query any further back in time
				if (oldestDetectedDate < new Date(earliestPossibleQueryDate)) return;

				return oldestDetectedDate.getTime();
			},
			onError: (error) => handleError(error),
			staleTime: 0,
		}
	);
};

export default useSearchIncidentsResults;
