import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { UploadCreateParams } from "gql/graphql";

const uploadCreateDocument = graphql(/* GraphQL */ `
	mutation uploadCreate($params: UploadCreateParams!) {
		uploadCreate(params: $params) {
			success
			s3_data {
				url
				fields {
					key
					value
				}
			}
			upload_status {
				...uploadStatusFields
			}
			error {
				code
				message
			}
		}
	}
`);

interface Args {
	params: UploadCreateParams;
	userId?: string;
}

// This is exposing the raw graphql request rather than a hook as we don't need
// it in a react context.
export function uploadCreate({ params, userId }: Args) {
	return client(userId)
		.request(uploadCreateDocument, { params })
		.then((res) => res.uploadCreate);
}
