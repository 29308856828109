import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listIncidentTypesDocument = graphql(/* GraphQL */ `
	query listIncidentTypes {
		listIncidentTypes {
			...incidentTypeFields
		}
	}
`);

interface Args {
	userId?: string;
}

const listIncidentTypes = ({ userId }: Args) =>
	client(userId)
		.request(listIncidentTypesDocument)
		.then((res) => res.listIncidentTypes);

const useListIncidentTypes = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const incidentTypesQueryKey = listIncidentTypesQueryKey(userId, _id);

	return useQuery(incidentTypesQueryKey, () => listIncidentTypes({ userId }), {
		cacheTime: 24 * 60 * 60 * 1000,
		enabled: !!userId,
		onError: (error) => handleError(error),
		staleTime: 24 * 60 * 60 * 1000,
	});
};

export default useListIncidentTypes;

export const listIncidentTypesQueryKey = (
	userId: string | undefined,
	_id: string | undefined
) => {
	return ["user", userId, "account", _id, "incidentTypes"];
};
