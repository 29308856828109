import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateWebAlertsStreamParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createWebAlertsStreamDocument = graphql(/* GraphQL */ `
	mutation CreateWebAlertsStream($params: CreateWebAlertsStreamParams!) {
		createWebAlertsStream(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateWebAlertsStreamParams;
	userId?: string;
}

const createWebAlertsStream = ({ params, userId }: Args) =>
	client(userId)
		.request(createWebAlertsStreamDocument, { params })
		.then((res) => res.createWebAlertsStream);

export function useCreateWebAlertsStream() {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateWebAlertsStreamParams) =>
			createWebAlertsStream({ params, userId }),
		{
			onSettled: () => {
				/** invalidate every streams query in case it includes the new stream */
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"streams",
				]);
			},
		}
	);
}
