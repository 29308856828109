import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SearchIncidentsAnalyticsDrilldownParams } from "gql/graphql";
import mapRangeToAfterDate from "utils/mapRangeToAfterDate";
import { useActiveAccount } from "utils/useActiveAccount";

const searchIncidentsAnalyticsDrilldownDocument = graphql(/* GraphQL */ `
	query searchIncidentsAnalyticsDrilldown(
		$params: SearchIncidentsAnalyticsDrilldownParams!
	) {
		searchIncidentsAnalyticsDrilldown(params: $params) {
			metrics {
				dimensions {
					value
				}
				value
			}
		}
	}
`);

interface Args {
	params: SearchIncidentsAnalyticsDrilldownParams;
	userId?: string;
}

const searchIncidentsAnalyticsDrilldown = ({ params, userId }: Args) => {
	const updatedParams = {
		...params,
		after: mapRangeToAfterDate(params.after),
	};

	return client(userId)
		.request(searchIncidentsAnalyticsDrilldownDocument, {
			params: updatedParams,
		})
		.then((res) => res.searchIncidentsAnalyticsDrilldown);
};

const useSearchIncidentsAnalyticsDrilldown = (
	params: SearchIncidentsAnalyticsDrilldownParams
) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		[
			"user",
			userId,
			"account",
			_id,
			"searchIncidentsAnalyticsDrilldown",
			params,
		],
		() => searchIncidentsAnalyticsDrilldown({ params, userId }),
		{
			/**
			 * This will get stale information (caching doesnt work) because params
			 * is a string '24h' for 24 hours ago, but updates `now` on call
			 * So cache and stale times have to stay at 0
			 */
			cacheTime: 0,
			enabled: !!userId && !!params.longkey && !!params.after,
			staleTime: 0,
		}
	);
};

export default useSearchIncidentsAnalyticsDrilldown;
