import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { DeleteResolverWebhookParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const deleteResolverWebhookDocument = graphql(/* GraphQL */ `
	mutation deleteResolverWebhook($params: DeleteResolverWebhookParams!) {
		deleteResolverWebhook(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: DeleteResolverWebhookParams;
	userId?: string;
}

const deleteResolverWebhook = ({ userId, params }: Args) =>
	client(userId)
		.request(deleteResolverWebhookDocument, { params })
		.then((res) => res.deleteResolverWebhook);

const useDeleteResolverWebhook = () => {
	const { userId, _id } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: DeleteResolverWebhookParams) =>
			deleteResolverWebhook({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"resolverWebhooks",
				]);
			},
		}
	);
};

export default useDeleteResolverWebhook;
