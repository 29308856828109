import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getPersonQueryKey } from "api/queries/useGetPerson";
import { getUserQueryKey } from "api/queries/useGetUser";
import { graphql } from "gql/gql";
import { UpdatePersonParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const updatePersonDocument = graphql(/* GraphQL */ `
	mutation updatePerson($params: UpdatePersonParams!) {
		updatePerson(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: UpdatePersonParams;
	userId?: string;
}

const updatePerson = ({ userId, params }: Args) =>
	client(userId)
		.request(updatePersonDocument, { params })
		.then((res) => res.updatePerson);

export const useUpdatePerson = () => {
	const { userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdatePersonParams) => updatePerson({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getPersonQueryKey());
				queryClient.invalidateQueries(getUserQueryKey());
			},
		}
	);
};
