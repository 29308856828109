import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import useSnackbar from "utils/useSnackbar";

const getPersonDocument = graphql(/* GraphQL */ `
	query getPerson {
		getPerson {
			...personFields
		}
	}
`);

function getPerson() {
	return client()
		.request(getPersonDocument)
		.then((res) => res.getPerson);
}

export function useGetPerson() {
	const { handleError } = useSnackbar();

	return useQuery(getPersonQueryKey(), () => getPerson(), {
		cacheTime: 24 * 60 * 60 * 1000,
		onError: (error) => handleError(error),
		retry: 1,
		staleTime: 24 * 60 * 60 * 1000,
	});
}

export const getPersonQueryKey = () => {
	return ["person"];
};
