import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { MarkEmailTrackerClickedParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const useMarkEmailTrackerClickedDocument = graphql(/* GraphQL */ `
	mutation markEmailTrackerClicked($params: MarkEmailTrackerClickedParams!) {
		markEmailTrackerClicked(params: $params) {
			error {
				code
				message
			}
			success
			deep_link_data {
				source
				alert_type
			}
		}
	}
`);

interface Args {
	params: MarkEmailTrackerClickedParams;
	userId?: string;
}

const markEmailTrackerClicked = ({ params, userId }: Args) =>
	client(userId)
		.request(useMarkEmailTrackerClickedDocument, { params })
		.then((res) => res.markEmailTrackerClicked);

const useMarkEmailTrackerClicked = () => {
	const { userId } = useActiveAccount();

	return useMutation((params: MarkEmailTrackerClickedParams) =>
		markEmailTrackerClicked({ params, userId })
	);
};

export default useMarkEmailTrackerClicked;
