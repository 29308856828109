import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateRegionBriefParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createRegionBriefDocument = graphql(/* GraphQL */ `
	mutation CreateRegionBrief($params: CreateRegionBriefParams!) {
		createRegionBrief(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateRegionBriefParams;
	userId?: string;
}

const createRegionBrief = ({ params, userId }: Args) =>
	client(userId)
		.request(createRegionBriefDocument, { params })
		.then((res) => res.createRegionBrief);

export function useCreateRegionBrief() {
	const { userId } = useActiveAccount();

	return useMutation((params: CreateRegionBriefParams) =>
		createRegionBrief({ params, userId })
	);
}
