import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const getAccountDocument = graphql(/* GraphQL */ `
	query GetAccount {
		getAccount {
			...accountFields
		}
	}
`);

interface Args {
	userId?: string;
}

const getAccount = ({ userId }: Args) =>
	client(userId)
		.request(getAccountDocument)
		.then((res) => res.getAccount);

const useGetAccount = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id],
		() => getAccount({ userId }),
		{
			enabled: !!userId,
			onError: (error) => handleError(error),
		}
	);
};

export default useGetAccount;
