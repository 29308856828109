import { useQuery, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetPostAuthorByUrlParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getPostAuthorDocument = graphql(/* GraphQL */ `
	query GetPostAuthorByUrl($params: GetPostAuthorByUrlParams!) {
		getPostAuthorByUrl(params: $params) {
			postAuthor: post_author {
				...postAuthorFields
			}
			error {
				message
			}
		}
	}
`);

interface Args {
	params: GetPostAuthorByUrlParams;
	userId?: string;
}

const getPostAuthorByUrl = async ({ userId, params }: Args) => {
	return client(userId)
		.request(getPostAuthorDocument, { params })
		.then((res) => res.getPostAuthorByUrl);
};

const queryOptions = {
	cacheTime: 10 * 60 * 1000,
	staleTime: 10 * 60 * 1000,
};

export function useGetPostAuthorByUrlQuery(params: GetPostAuthorByUrlParams) {
	const { userId, _id } = useActiveAccount();

	return useQuery(
		queryKey(userId, _id, params),
		() => getPostAuthorByUrl({ params, userId }),
		queryOptions
	);
}

export function useGetPostAuthorByUrl() {
	const client = useQueryClient();
	const { userId, _id } = useActiveAccount();

	return (params: GetPostAuthorByUrlParams) =>
		client.fetchQuery(
			queryKey(userId, _id, params),
			() => getPostAuthorByUrl({ params, userId }),
			queryOptions
		);
}

function queryKey(
	userId: string | undefined,
	accountId: string | undefined,
	params: GetPostAuthorByUrlParams
) {
	return ["user", userId, "account", accountId, "getPostAuthorByUrl", params];
}
