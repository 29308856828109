import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetWhereByLongkeyParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getWhereByLongkeyDocument = graphql(/* GraphQL */ `
	query getWhereByLongkey($params: GetWhereByLongkeyParams!) {
		getWhereByLongkey(params: $params) {
			compact_display
			display
			geo {
				lat
				lng
			}
		}
	}
`);

interface Args {
	params: GetWhereByLongkeyParams;
	userId?: string;
}

const getWhereByLongkey = ({ userId, params }: Args) =>
	client(userId)
		.request(getWhereByLongkeyDocument, { params })
		.then((res) => res.getWhereByLongkey);

const useGetWhereByLongkey = (params: GetWhereByLongkeyParams) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "whereByLongkey", params],
		() => getWhereByLongkey({ params, userId }),
		{
			cacheTime: 24 * 60 * 60 * 1000,
			enabled: !!userId && !!params.longkey,
			staleTime: 24 * 60 * 60 * 1000,
		}
	);
};

export default useGetWhereByLongkey;
