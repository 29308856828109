import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { listAlertsQueryKey } from "api/queries/useListAlerts";
import { graphql } from "gql/gql";
import { UpdateWebAlertsStreamParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const updateWebAlertsStreamDocument = graphql(/* GraphQL */ `
	mutation UpdateWebAlertsStream($params: UpdateWebAlertsStreamParams!) {
		updateWebAlertsStream(params: $params) {
			error {
				code
				message
			}
			success
			stream {
				_id
			}
		}
	}
`);

interface Args {
	params: UpdateWebAlertsStreamParams;
	userId?: string;
}

const updateWebAlertsStream = ({ params, userId }: Args) =>
	client(userId)
		.request(updateWebAlertsStreamDocument, { params })
		.then((res) => res.updateWebAlertsStream);

export function useUpdateWebAlertsStream() {
	const { _id: accountId, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdateWebAlertsStreamParams) =>
			updateWebAlertsStream({ params, userId }),
		{
			onSettled: (data, error, variables) => {
				const streamId = data?.stream?._id ?? variables._id;

				/** invalidate every streams query in case it includes the new stream */
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					accountId,
					"streams",
				]);

				if (streamId) {
					/** invalidate this stream query because it's changed */
					queryClient.invalidateQueries([
						"user",
						userId,
						"account",
						accountId,
						"stream",
						streamId,
					]);
					/** clear all data from any listAlerts queries that include this streamId because people don't want to see data from before the stream was edited */
					queryClient.removeQueries(
						listAlertsQueryKey(userId, accountId, [streamId])
					);
				}
			},
		}
	);
}
