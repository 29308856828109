import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const signoutDocument = graphql(/* GraphQL */ `
	mutation signout {
		signout {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	userId?: string;
}

const signout = ({ userId }: Args) =>
	client(userId)
		.request(signoutDocument)
		.then((res) => res.signout);

const useSignout = () => {
	const { userId } = useActiveAccount();
	const queryClient = useQueryClient();
	const { handleError } = useSnackbar();

	return useMutation(() => signout({ userId }), {
		onSuccess: (data) => {
			if (data.success) {
				queryClient.clear();
			} else if (data.error) {
				handleError(data.error);
			}
		},
	});
};

export default useSignout;
