import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { createListEverbridgeIntegrationsQueryKey } from "api/queries/useListEverbridgeIntegrations";
import { graphql } from "gql/gql";
import { CreateEverbridgeIntegrationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createEverbridgeIntegrationDocument = graphql(/* GraphQL */ `
	mutation createEverbridgeIntegration(
		$params: CreateEverbridgeIntegrationParams!
	) {
		createEverbridgeIntegration(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateEverbridgeIntegrationParams;
	userId?: string;
}

const createEverbridgeIntegration = ({ userId, params }: Args) =>
	client(userId)
		.request(createEverbridgeIntegrationDocument, { params })
		.then((res) => res.createEverbridgeIntegration);

const useCreateEverbridgeIntegration = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateEverbridgeIntegrationParams) =>
			createEverbridgeIntegration({ params, userId }),
		{
			onSettled: () => {
				const listEverbridgeIntegrationsQueryKey =
					createListEverbridgeIntegrationsQueryKey(userId, _id);

				queryClient.invalidateQueries(listEverbridgeIntegrationsQueryKey);
			},
		}
	);
};

export default useCreateEverbridgeIntegration;
