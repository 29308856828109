import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getDashboardQueryKey } from "api/queries/useGetDashboard";
import { AlertsTab, Dashboard } from "autoGenSubTypes";
import { graphql } from "gql/gql";
import {
	UpdateAlertsTabDataInput,
	UpdateDashboardTabParams,
} from "gql/graphql";
import { NonNullableObject } from "types/utils";
import deepCopy from "utils/deepCopy";
import { useActiveAccount } from "utils/useActiveAccount";

const updateDashboardTabDocument = graphql(/* GraphQL */ `
	mutation updateDashboardTab($params: UpdateDashboardTabParams!) {
		updateDashboardTab(params: $params) {
			error {
				code
				message
			}
			success
			tab {
				__typename
				... on AlertsTab {
					__typename
					alerts_tab_data {
						bottom {
							stream_id
						}
						name
						top {
							stream_id
						}
					}
				}
			}
		}
	}
`);

interface Args {
	params: UpdateDashboardTabParams;
	userId?: string;
}

const updateDashboardTab = ({ userId, params }: Args) =>
	client(userId)
		.request(updateDashboardTabDocument, { params })
		.then((res) => res.updateDashboardTab);

const useUpdateDashboardTab = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	const dashboardQueryKey = getDashboardQueryKey(userId, _id);

	return useMutation(
		(params: UpdateDashboardTabParams) =>
			updateDashboardTab({ params, userId }),
		{
			onMutate: async (variables) => {
				/** optimistic updates for alertsTab(s) */
				if (variables.tab.type === "ALERTS") {
					await queryClient.cancelQueries(dashboardQueryKey, { exact: true });

					const prevDashboard = queryClient.getQueryData(dashboardQueryKey, {
						exact: true,
					});

					queryClient.setQueryData<Dashboard>(dashboardQueryKey, (oldData) => {
						/** need check so typescript knows that there will be dashboard data */
						if (oldData === undefined || !oldData?.tabs) return oldData;

						const updatedTabs = deepCopy(oldData);
						const updatedTabIndex = oldData.tabs.findIndex(
							(tab) => tab.key === variables.key
						);

						if (updatedTabIndex !== -1 && updatedTabIndex !== undefined) {
							(updatedTabs.tabs[updatedTabIndex] as AlertsTab).alerts_tab_data =
								{
									...(oldData.tabs[updatedTabIndex] as AlertsTab)
										.alerts_tab_data,
									...(variables.tab
										.alerts_tab_data as NonNullableObject<UpdateAlertsTabDataInput>),
								};
						}

						return updatedTabs;
					});
					return { prevDashboard };
				}
			},
			onSettled: (data, error, variables, context) => {
				/** on error, set cache back to what it was before the optimistic update */
				if ((data?.error || error) && context?.prevDashboard) {
					queryClient.setQueryData(dashboardQueryKey, context.prevDashboard);
				}

				/** Always refetch after error or success */
				queryClient.invalidateQueries(dashboardQueryKey, { exact: true });

				/** we need to invalidate related listAlertQueries so they don't show stale data from a stream that was edited for too long. It will show old data but start to refetch the correct data right away because of the cache invalidation */

				// TODO: https://samdesk.atlassian.net/browse/DEV-5053
				if (data?.tab?.__typename === "AlertsTab") {
					const topPane = data?.tab?.alerts_tab_data.top.map(
						(stream) => stream.stream_id
					);
					const bottomPane = data.tab.alerts_tab_data.bottom.map(
						(stream) => stream.stream_id
					);

					queryClient.invalidateQueries([
						"user",
						userId,
						"account",
						_id,
						"alerts",
						topPane,
					]);
					queryClient.invalidateQueries([
						"user",
						userId,
						"account",
						_id,
						"alerts",
						bottomPane,
					]);
				}
			},
		}
	);
};

export default useUpdateDashboardTab;
