import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListOutgoingWebhooksParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listOutgoingWebhooksDocument = graphql(/* GraphQL */ `
	query listOutgoingWebhooks($params: ListOutgoingWebhooksParams!) {
		listOutgoingWebhooks(params: $params) {
			_id
			account_id
			disabled
			name
			url
			header_key
			header_value
			created
			updated
			user_id
		}
	}
`);

interface Args {
	params: ListOutgoingWebhooksParams;
	userId?: string;
}

const listOutgoingWebhooks = ({ userId, params }: Args) =>
	client(userId)
		.request(listOutgoingWebhooksDocument, { params })
		.then((res) => res.listOutgoingWebhooks);

const useListOutgoingWebhooks = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	const params = {};

	return useQuery(
		["user", userId, "account", _id, "outgoingWebhooks"],
		() => listOutgoingWebhooks({ params, userId }),
		{
			enabled: !!userId,
			onError: (error) => handleError(error),
		}
	);
};

export default useListOutgoingWebhooks;
