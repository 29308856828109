import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SearchIncidentsAnalyticsHeatmapParams } from "gql/graphql";
import mapRangeToAfterDate from "utils/mapRangeToAfterDate";
import { useActiveAccount } from "utils/useActiveAccount";

const searchIncidentsAnalyticsHeatmapDocument = graphql(/* GraphQL */ `
	query searchIncidentsAnalyticsHeatmap(
		$params: SearchIncidentsAnalyticsHeatmapParams!
	) {
		searchIncidentsAnalyticsHeatmap(params: $params) {
			points {
				count
				lat
				lng
			}
		}
	}
`);

interface Args {
	params: SearchIncidentsAnalyticsHeatmapParams;
	userId?: string;
}

const searchIncidentsAnalyticsHeatmap = async ({ params, userId }: Args) => {
	const updatedParams = {
		...params,
		after: mapRangeToAfterDate(params.after),
	};

	return client(userId)
		.request(searchIncidentsAnalyticsHeatmapDocument, { params: updatedParams })
		.then((res) => res.searchIncidentsAnalyticsHeatmap);
};

const useSearchIncidentsAnalyticsHeatmap = (
	params: SearchIncidentsAnalyticsHeatmapParams
) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "searchIncidentsAnalyticsHeatmap", params],
		() => searchIncidentsAnalyticsHeatmap({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId && !!params.longkey,
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useSearchIncidentsAnalyticsHeatmap;
