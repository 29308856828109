import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";

const createDeletePersonRequestDocument = graphql(/* GraphQL */ `
	mutation createDeletePersonRequest {
		createDeletePersonRequest {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	userId?: string;
}

const createDeletePersonRequest = ({ userId }: Args) =>
	client(userId)
		.request(createDeletePersonRequestDocument)
		.then((res) => res.createDeletePersonRequest);

const useCreateDeletePersonRequest = () => {
	const { userId } = useActiveAccount();

	return useMutation(() => createDeletePersonRequest({ userId }));
};

export default useCreateDeletePersonRequest;
