import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CancelChangeEmailRequestParams } from "gql/graphql";

const cancelChangeEmailRequestDocument = graphql(/* GraphQL */ `
	mutation cancelChangeEmailRequest($params: CancelChangeEmailRequestParams!) {
		cancelChangeEmailRequest(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	controller: AbortController;
	params: CancelChangeEmailRequestParams;
}

const cancelChangeEmailRequest = ({ params, controller }: Args) =>
	client(undefined, controller)
		.request(cancelChangeEmailRequestDocument, { params })
		.then((res) => res.cancelChangeEmailRequest);

interface Params {
	controller: AbortController;
	params: CancelChangeEmailRequestParams;
}

const useCancelChangeEmailRequest = () => {
	return useMutation(({ params, controller }: Params) =>
		cancelChangeEmailRequest({ controller, params })
	);
};

export default useCancelChangeEmailRequest;
