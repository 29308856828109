import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SubmitChangeEmailRequestParams } from "gql/graphql";

const submitChangeEmailRequestDocument = graphql(/* GraphQL */ `
	mutation submitChangeEmailRequest($params: SubmitChangeEmailRequestParams!) {
		submitChangeEmailRequest(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	controller: AbortController;
	params: SubmitChangeEmailRequestParams;
}

const submitChangeEmailRequest = ({ params, controller }: Args) =>
	client(undefined, controller)
		.request(submitChangeEmailRequestDocument, { params })
		.then((res) => res.submitChangeEmailRequest);

interface Params {
	controller: AbortController;
	params: SubmitChangeEmailRequestParams;
}

const useSubmitChangeEmailRequest = () => {
	const queryClient = useQueryClient();

	return useMutation(
		({ params, controller }: Params) =>
			submitChangeEmailRequest({ controller, params }),
		{
			onSettled: (data) => {
				if (data?.success) {
					queryClient.invalidateQueries(["person"]);
				}
			},
		}
	);
};

export default useSubmitChangeEmailRequest;
