import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { MarkReadParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const useMarkReadDocument = graphql(/* GraphQL */ `
	mutation MarkRead($params: MarkReadParams!) {
		markRead(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: MarkReadParams;
	userId?: string;
}

const markRead = ({ userId, params }: Args) =>
	client(userId)
		.request(useMarkReadDocument, { params })
		.then((res) => res.markRead);

export const useMarkRead = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation((params: MarkReadParams) => markRead({ params, userId }), {
		onSettled: () => {
			queryClient.invalidateQueries(["user", userId, "account", _id, "alerts"]);
		},
	});
};
