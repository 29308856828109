import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SendIncidentToResolverParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const sendIncidentToResolverDocument = graphql(/* GraphQL */ `
	mutation sendIncidentToResolver($params: SendIncidentToResolverParams!) {
		sendIncidentToResolver(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: SendIncidentToResolverParams;
	userId?: string;
}

const sendIncidentToResolver = ({ params, userId }: Args) =>
	client(userId)
		.request(sendIncidentToResolverDocument, { params })
		.then((res) => res.sendIncidentToResolver);

const useSendIncidentToResolver = () => {
	const { userId } = useActiveAccount();

	return useMutation(({ params }: Args) =>
		sendIncidentToResolver({ params, userId })
	);
};

export default useSendIncidentToResolver;
