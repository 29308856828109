import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { LogIncidentInteractionParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const useLogIncidentInteractionDocument = graphql(/* GraphQL */ `
	mutation logIncidentInteraction($params: LogIncidentInteractionParams!) {
		logIncidentInteraction(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: LogIncidentInteractionParams;
	userId?: string;
}

const logIncidentInteraction = ({ userId, params }: Args) =>
	client(userId)
		.request(useLogIncidentInteractionDocument, { params })
		.then((res) => res.logIncidentInteraction);

const useLogIncidentInteraction = () => {
	const { userId } = useActiveAccount();

	return useMutation((params: LogIncidentInteractionParams) =>
		logIncidentInteraction({ params, userId })
	);
};

export default useLogIncidentInteraction;
