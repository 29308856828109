import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { UpdateAccountParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const updateAccountDocument = graphql(/* GraphQL */ `
	mutation updateAccount($params: UpdateAccountParams!) {
		updateAccount(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: UpdateAccountParams;
	userId?: string;
}

const updateAccount = ({ userId, params }: Args) =>
	client(userId)
		.request(updateAccountDocument, { params })
		.then((res) => res.updateAccount);

const useUpdateAccount = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpdateAccountParams) => updateAccount({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(["user", userId, "account", _id], {
					exact: true,
				});
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				}
			},
		}
	);
};

export default useUpdateAccount;
