import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SubmitMFACodeParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const submitMFACodeDocument = graphql(/* GraphQL */ `
	mutation submitMFACode($params: SubmitMFACodeParams!) {
		submitMFACode(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: SubmitMFACodeParams;
	userId?: string;
}

const submitMFACode = ({ userId, params }: Args) =>
	client(userId)
		.request(submitMFACodeDocument, { params })
		.then((res) => res.submitMFACode);

interface useMutationArgs {
	params: SubmitMFACodeParams;
}

const useSubmitMFACode = () => {
	const { userId } = useActiveAccount();
	const { handleError } = useSnackbar();
	const queryClient = useQueryClient();

	return useMutation(
		({ params }: useMutationArgs) => submitMFACode({ params, userId }),
		{
			onSettled: (data) => {
				if (data?.success) {
					queryClient.invalidateQueries(["person"], { exact: true });
				}
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				}
				// TODO DEV-4944: onSuccess can toggle the value optimistically in the person cache
			},
		}
	);
};

export default useSubmitMFACode;
