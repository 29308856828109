import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetIncidentSummarySessionFollowupParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getIncidentSummarySessionFollowupDocument = graphql(/* GraphQL */ `
	query getIncidentSummarySessionFollowup(
		$params: GetIncidentSummarySessionFollowupParams!
	) {
		getIncidentSummarySessionFollowup(params: $params) {
			error {
				code
				message
			}
			success
			text: text
		}
	}
`);

interface Args {
	params: GetIncidentSummarySessionFollowupParams;
	userId?: string;
}

const getIncidentSummarySessionFollowup = ({ params, userId }: Args) => {
	return client(userId)
		.request(getIncidentSummarySessionFollowupDocument, { params })
		.then((res) => res.getIncidentSummarySessionFollowup);
};

const useGetIncidentSummarySessionFollowup = (
	params: GetIncidentSummarySessionFollowupParams
) => {
	const { _id, userId } = useActiveAccount();

	const queryKey = createGetIncidentSummarySessionFollowupQueryKey({
		_id,
		gid: params.followup_question_gid,
		userId,
	});

	return useQuery(
		queryKey,
		() => getIncidentSummarySessionFollowup({ params, userId }),
		{
			enabled: !!userId && !!params.followup_question_gid,
		}
	);
};

const createGetIncidentSummarySessionFollowupQueryKey = ({
	_id,
	gid,
	userId,
}: {
	_id: string | undefined;
	gid: string;
	userId: string | undefined;
}) => {
	return [
		"user",
		userId,
		"account",
		_id,
		"getIncidentSummarySessionFollowup",
		gid,
	];
};

export { useGetIncidentSummarySessionFollowup };
