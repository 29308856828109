import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetIncidentFeaturedContentParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getIncidentFeaturedContentDocument = graphql(/* GraphQL */ `
	query getIncidentFeaturedContent($params: GetIncidentFeaturedContentParams!) {
		getIncidentFeaturedContent(params: $params) {
			items {
				__typename
				key
				embed_data {
					embed_code
				}
				incident_data {
					incident_id
				}
				note_data {
					created_at
					text
				}
				post_data {
					author_avatar
					author_handle
					author_name
					author_permalink
					created_at
					medias {
						small_url
						large_url
						type
						video_url
					}
					permalink
					social_type
					subtext
					text
				}
			}
		}
	}
`);

interface Args {
	params: GetIncidentFeaturedContentParams;
	userId?: string;
}

const getIncidentFeaturedContent = ({ params, userId }: Args) =>
	client(userId)
		.request(getIncidentFeaturedContentDocument, { params })
		.then((res) => res.getIncidentFeaturedContent);

const useGetIncidentFeaturedContent = (
	params: GetIncidentFeaturedContentParams
) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		[
			"user",
			userId,
			"account",
			_id,
			"incidentFeaturedContent",
			params.incident_id,
		],
		() => getIncidentFeaturedContent({ params, userId }),
		{
			cacheTime: 30 * 1000,
			enabled: !!userId && !!params.incident_id,
			refetchInterval: 30 * 1000,
			staleTime: 30 * 1000,
		}
	);
};

export default useGetIncidentFeaturedContent;
