import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { DeleteProximityAssetsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const deleteProximityAssetsDocument = graphql(/* GraphQL */ `
	mutation deleteProximityAssets($params: DeleteProximityAssetsParams!) {
		deleteProximityAssets(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: DeleteProximityAssetsParams;
	userId?: string;
}

const deleteProximityAssets = ({ userId, params }: Args) =>
	client(userId)
		.request(deleteProximityAssetsDocument, { params })
		.then((res) => res.deleteProximityAssets);

const useDeleteProximityAssets = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError, handleSuccess } = useSnackbar();
	const queryClient = useQueryClient();

	return useMutation(
		(params: DeleteProximityAssetsParams) =>
			deleteProximityAssets({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"proximityAssets",
				]);

				/** deleting an asset changes the asset count, so we have to invalidate all asset set caches so that assets load correctly on the map */
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"proximityAssetSets",
				]);
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				} else if (data.success) {
					handleSuccess("Asset has been removed.");
				}
			},
		}
	);
};

export default useDeleteProximityAssets;
