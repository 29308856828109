import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateChangeEmailRequestParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createChangeEmailRequestDocument = graphql(/* GraphQL */ `
	mutation createChangeEmailRequest($params: CreateChangeEmailRequestParams!) {
		createChangeEmailRequest(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateChangeEmailRequestParams;
	userId?: string;
}

const createChangeEmailRequest = ({ userId, params }: Args) =>
	client(userId)
		.request(createChangeEmailRequestDocument, { params })
		.then((res) => res.createChangeEmailRequest);

const useCreateChangeEmailRequest = () => {
	const { userId } = useActiveAccount();

	return useMutation((params: CreateChangeEmailRequestParams) =>
		createChangeEmailRequest({ params, userId })
	);
};

export default useCreateChangeEmailRequest;
