import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { searchLocationsQuery } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const searchLocationsDocument = graphql(/* GraphQL */ `
	query searchLocations($params: SearchLocationsParams!) {
		searchLocations(params: $params) {
			__typename
			_id
			key
			label
			longkey
			name
			type
		}
	}
`);

export type Location = searchLocationsQuery["searchLocations"][number];

interface Args {
	search: string;
	userId?: string;
}

const searchLocations = ({ search, userId }: Args) => {
	const params = {
		query: search,
	};

	return client(userId)
		.request(searchLocationsDocument, { params })
		.then((res) => res.searchLocations);
};

const useSearchLocations = (search: string) => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id, "searchLocations", search],
		() => searchLocations({ search, userId }),
		{
			enabled: !!_id && !!search && search.length > 2,
			onError: (error) => handleError(error),
		}
	);
};

export default useSearchLocations;
