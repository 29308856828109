import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetIncidentParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getIncidentDocument = graphql(/* GraphQL */ `
	query getIncident($params: GetIncidentParams!) {
		getIncident(params: $params) {
			...incidentFields
		}
	}
`);

interface Args {
	params: GetIncidentParams;
	userId?: string;
}

const getIncident = ({ params, userId }: Args) => {
	return client(userId)
		.request(getIncidentDocument, { params })
		.then((res) => res.getIncident);
};

const useGetIncident = (params: GetIncidentParams) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "incident", params._id],
		() => getIncident({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId && !!params._id,
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useGetIncident;
