import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetAccountInvitationParams } from "gql/graphql";
import useSnackbar from "utils/useSnackbar";

const getAccountInvitationDocument = graphql(/* GraphQL */ `
	query getAccountInvitation($params: GetAccountInvitationParams!) {
		getAccountInvitation(params: $params) {
			personName: person_name
			accountName: account_name
			passwordPolicy: password_policy {
				min_length
				require_lowercase
				require_number
				require_special
				require_uppercase
			}
			pending_user_account {
				email
			}
			samlIssuer: saml_issuer
		}
	}
`);

interface Args {
	params: GetAccountInvitationParams;
}

function getAccountInvitation({ params }: Args) {
	return client()
		.request(getAccountInvitationDocument, { params })
		.then((res) => res.getAccountInvitation);
}

export function useGetAccountInvitation(params: GetAccountInvitationParams) {
	const { handleError } = useSnackbar();

	return useQuery(
		["accountInvitation", params.verification_code],
		() => getAccountInvitation({ params }),
		{
			enabled: !!params.verification_code,
			onError: (error) => handleError(error),
		}
	);
}
