import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const getSAMLConfigurationDocument = graphql(/* GraphQL */ `
	query getSAMLConfiguration {
		getSAMLConfiguration {
			certs
			endpoint
			issuer
		}
	}
`);

const getSAMLConfiguration = (userId?: string) =>
	client(userId)
		.request(getSAMLConfigurationDocument)
		.then((res) => res.getSAMLConfiguration);

const useGetSAMLConfiguration = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		getSAMLConfigurationQueryKey(userId, _id),
		() => getSAMLConfiguration(userId),
		{
			enabled: !!_id && !!userId,
			onError: (error) => handleError(error),
		}
	);
};

export default useGetSAMLConfiguration;

export const getSAMLConfigurationQueryKey = (
	userId: string | undefined,
	_id: string | undefined
) => {
	return ["user", userId, "account", _id, "SAMLConfiguration"];
};
