import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SearchRecentIncidentsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const searchRecentIncidentsDocument = graphql(/* GraphQL */ `
	query searchRecentIncidents($params: SearchRecentIncidentsParams!) {
		searchRecentIncidents(params: $params) {
			_id
			gid
			headline {
				source
				text
			}
			detected_date
			polygons
			severity
			status
			types
			geometries {
				...geometryFields
			}
			wheres {
				compact_display
				display
				geo {
					lat
					lng
				}
				longkey
			}
		}
	}
`);

interface Args {
	params: SearchRecentIncidentsParams;
	userId?: string;
}

const searchRecentIncidents = ({ params, userId }: Args) =>
	client(userId)
		.request(searchRecentIncidentsDocument, { params })
		.then((res) => res.searchRecentIncidents);

const useSearchRecentIncidents = (params: SearchRecentIncidentsParams) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "searchRecentIncidents", params],
		() => searchRecentIncidents({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId && !!params.longkeys,
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useSearchRecentIncidents;
