import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getSAMLConfigurationQueryKey } from "api/queries/useGetSAMLConfiguration";
import { graphql } from "gql/gql";
import { UpsertSAMLConfigurationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const upsertSAMLConfigurationDocument = graphql(/* GraphQL */ `
	mutation upsertSAMLConfiguration($params: UpsertSAMLConfigurationParams!) {
		upsertSAMLConfiguration(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: UpsertSAMLConfigurationParams;
	userId?: string;
}

const upsertSAMLConfiguration = ({ userId, params }: Args) =>
	client(userId)
		.request(upsertSAMLConfigurationDocument, { params })
		.then((res) => res.upsertSAMLConfiguration);

const useUpsertSAMLConfiguration = () => {
	const { userId, _id } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: UpsertSAMLConfigurationParams) =>
			upsertSAMLConfiguration({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(
					getSAMLConfigurationQueryKey(userId, _id)
				);
			},
		}
	);
};

export default useUpsertSAMLConfiguration;
