import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccountIndex } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";
import { useGetPerson } from "./useGetPerson";

const getUserDocument = graphql(/* GraphQL */ `
	query getUser {
		getUser {
			...userFields
		}
	}
`);

interface Args {
	userId?: string;
}

const getUser = ({ userId }: Args) =>
	client(userId)
		.request(getUserDocument)
		.then((res) => res.getUser);

function useGetUser() {
	const { handleError } = useSnackbar();
	const { activeAccountIndex } = useActiveAccountIndex();
	const { data: { partialAccounts } = {} } = useGetPerson();

	const activeUserId = partialAccounts?.find(
		(partialAccount) => partialAccount.index === activeAccountIndex
	)?.userId;

	return useQuery(getUserQueryKey(), () => getUser({ userId: activeUserId }), {
		cacheTime: 24 * 60 * 60 * 1000,
		enabled: activeUserId != undefined,
		onError: (error) => handleError(error),
		staleTime: 24 * 60 * 60 * 1000,
	});
}

export default useGetUser;

export const getUserQueryKey = () => {
	return ["user"];
};
