import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client";

export function getApolloCache() {
	return new InMemoryCache({
		// We should switch to generating these automatically.
		// This is probably the best way to do it:
		// https://the-guild.dev/graphql/codegen/plugins/other/fragment-matcher
		//
		// More Apollo docs:
		// https://www.apollographql.com/docs/react/data/fragments#generating-possibletypes-automatically
		possibleTypes: {
			IncidentResponseAction: [
				"IncidentResponseActionAcknowledged",
				"IncidentResponseActionNotificationCompleted",
				"IncidentResponseActionNotificationProcessed",
				"IncidentResponseActionNotificationQueued",
				"IncidentResponseActionNotified",
				"IncidentResponseActionNotifiedResponded",
				"IncidentResponseActionNotifiedViewed",
				"IncidentResponseActionNotifyInitiated",
				"IncidentResponseActionResolved",
			],
		},
		dataIdFromObject: (responseObject) => {
			if (
				typeof responseObject.gid === "string" &&
				typeof responseObject.__typename === "string"
			) {
				return `${responseObject.gid}`;
			}
			return defaultDataIdFromObject(responseObject);
		},
	});
}
