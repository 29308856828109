import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateMsTeamsWebhookParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createMsTeamsWebhookDocument = graphql(/* GraphQL */ `
	mutation createMsTeamsWebhook($params: CreateMsTeamsWebhookParams!) {
		createMsTeamsWebhook(params: $params) {
			ms_teams_webhook {
				_id
				account_id
				url
				name
				created
				updated
				user_id
				disabled
			}
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateMsTeamsWebhookParams;
	userId?: string;
}

const createMsTeamsWebhook = ({ params, userId }: Args) =>
	client(userId)
		.request(createMsTeamsWebhookDocument, { params })
		.then((res) => res.createMsTeamsWebhook);

const useCreateMsTeamsWebhook = () => {
	const { userId, _id } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateMsTeamsWebhookParams) =>
			createMsTeamsWebhook({ params, userId }),
		{
			onSettled: () =>
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"msTeamsWebhooks",
				]),
		}
	);
};

export default useCreateMsTeamsWebhook;
