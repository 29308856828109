import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListProximityAssetSetsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listProximityAssetSetsDocument = graphql(/* GraphQL */ `
	query listProximityAssetSets($params: ListProximityAssetSetsParams!) {
		listProximityAssetSets(params: $params) {
			_id
			gid
			account_id
			asset_count
			created
			name
			proximity
			updated
		}
	}
`);

interface Args {
	params: ListProximityAssetSetsParams;
	userId?: string;
}

const listProximityAssetSets = ({ params, userId }: Args) =>
	client(userId)
		.request(listProximityAssetSetsDocument, { params })
		.then((res) => res.listProximityAssetSets);

const useListProximityAssetSets = (params: ListProximityAssetSetsParams) => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id, "proximityAssetSets", params],
		() => listProximityAssetSets({ params, userId }),
		{
			enabled: !!userId && (!!params._ids?.length || params._ids === undefined),
			onError: (error) => handleError(error),
		}
	);
};

export default useListProximityAssetSets;
