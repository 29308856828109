import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { IncidentAnalytics, IncidentAnalyticsMetrics } from "autoGenSubTypes";
import dayjs, { Dayjs, ManipulateType, OpUnitType } from "dayjs";
import { graphql } from "gql/gql";
import { SearchIncidentsAnalyticsParams } from "gql/graphql";
import mapRangeToAfterDate from "utils/mapRangeToAfterDate";
import { useActiveAccount } from "utils/useActiveAccount";

const searchIncidentsAnalyticsDocument = graphql(/* GraphQL */ `
	query searchIncidentsAnalytics($params: SearchIncidentsAnalyticsParams!) {
		searchIncidentsAnalytics(params: $params) {
			metrics {
				dimensions {
					value
				}
				value
			}
		}
	}
`);

interface Args {
	params: SearchIncidentsAnalyticsParams;
	userId?: string;
}

const searchIncidentsAnalytics = async ({ params, userId }: Args) => {
	const updatedParams = {
		...params,
		after: mapRangeToAfterDate(params.after),
	};

	return client(userId)
		.request(searchIncidentsAnalyticsDocument, { params: updatedParams })
		.then((res) => {
			// when we pass a bucket_interval, we're asking for a line chart
			if (params.bucket_interval) {
				const paddedData = padIncidentsAnalyticsData({
					after: updatedParams.after,
					before: params.before,
					bucketInterval: params.bucket_interval,
					data: res.searchIncidentsAnalytics,
				});
				return paddedData;
			}
			return res.searchIncidentsAnalytics;
		});
};

const useSearchIncidentsAnalytics = (
	params: SearchIncidentsAnalyticsParams
) => {
	const { _id, userId } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "searchIncidentAnalytics", params],
		() => searchIncidentsAnalytics({ params, userId }),
		{
			/**
			 * This will get stale information (caching doesnt work) because params
			 * is a string '24h' for 24 hours ago, but updates `now` on call
			 * So cache and stale times have to stay at 0
			 */
			cacheTime: 0,
			enabled: !!userId && !!params.longkey && !!params.after,
			staleTime: 0,
		}
	);
};

export default useSearchIncidentsAnalytics;

interface ArgsForPadIncidentsAnalyticsData {
	after: SearchIncidentsAnalyticsParams["after"];
	before: SearchIncidentsAnalyticsParams["before"];
	bucketInterval: SearchIncidentsAnalyticsParams["bucket_interval"];
	data: IncidentAnalytics;
}

/** Ensures the data fills out the entire date range provided */
const padIncidentsAnalyticsData = ({
	after,
	before,
	bucketInterval,
	data,
}: ArgsForPadIncidentsAnalyticsData) => {
	const startDate = dayjs(after);
	const endDate = before ? dayjs(before) : dayjs();

	let increment: ManipulateType = "d";
	let comparisonUnit: OpUnitType = "day";
	switch (bucketInterval) {
		case "1y":
			increment = "y";
			comparisonUnit = "year";
			break;
		case "1M":
			increment = "M";
			comparisonUnit = "month";
			break;
		case "1w":
			increment = "w";
			comparisonUnit = "week";
			break;
		case "1h":
			increment = "h";
			comparisonUnit = "hour";
			break;
		default:
			break;
	}

	// the query didn't return any data, so fill the date range with 0 values
	if (data && data.metrics.length === 0) {
		const paddedEntries = createPaddedData({
			comparisonUnit,
			dateToPad: startDate,
			goalDate: endDate,
			increment,
			shouldPad: true,
		});
		return { metrics: paddedEntries };
	}

	// pad the start of the data
	const firstDateInData = dayjs(data.metrics[0].dimensions[0].value);
	const firstDateInDataIsAfterStartDate = firstDateInData.isAfter(
		startDate,
		comparisonUnit
	);
	const startOfDataPaddedEntries = createPaddedData({
		comparisonUnit,
		dateToPad: firstDateInData,
		goalDate: startDate,
		increment,
		shouldPad: firstDateInDataIsAfterStartDate,
	});

	// pad the end of the data
	const lastDateInData = dayjs(
		data.metrics[data.metrics.length - 1].dimensions[0].value
	);
	const lastDateInDataIsBeforeEndDate = lastDateInData.isBefore(
		endDate,
		comparisonUnit
	);
	const endOfDataPaddedEntries = createPaddedData({
		comparisonUnit,
		dateToPad: lastDateInData.add(1, increment),
		goalDate: endDate,
		increment,
		shouldPad: lastDateInDataIsBeforeEndDate,
	});

	const paddedDataMetrics = [
		...startOfDataPaddedEntries,
		...data.metrics,
		...endOfDataPaddedEntries,
	];
	const paddedData = { metrics: paddedDataMetrics };

	return paddedData;
};

interface ArgsForPaddedData {
	comparisonUnit: OpUnitType;
	dateToPad: Dayjs;
	goalDate: Dayjs;
	increment: ManipulateType;
	shouldPad: boolean;
}

const createPaddedData = ({
	comparisonUnit,
	dateToPad,
	goalDate,
	increment,
	shouldPad,
}: ArgsForPaddedData) => {
	const paddedEntries: IncidentAnalyticsMetrics = [];
	if (!shouldPad) return paddedEntries;

	while (dateToPad.isBefore(goalDate, comparisonUnit)) {
		const paddedEntry = {
			dimensions: [
				{
					value: dateToPad.toISOString(),
				},
			],
			value: 0,
		};
		paddedEntries.push(paddedEntry);
		dateToPad = dateToPad.add(1, increment);
	}

	return paddedEntries;
};
