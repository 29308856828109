import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listEverbridgeVCCCategoriesDocument = graphql(/* GraphQL */ `
	query listEverbridgeVCCCategories {
		listEverbridgeVCCCategories {
			_id
			category
		}
	}
`);

interface Args {
	userId?: string;
}

const listEverbridgeVCCCategories = ({ userId }: Args) => {
	return client(userId)
		.request(listEverbridgeVCCCategoriesDocument)
		.then((res) => res.listEverbridgeVCCCategories);
};

const useListEverbridgeVCCCategories = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id, "everbridgeVCCCategories"],
		() => listEverbridgeVCCCategories({ userId }),
		{
			cacheTime: 24 * 60 * 60 * 1000,
			enabled: !!userId,
			onError: (err) => handleError(err),
			staleTime: 24 * 60 * 60 * 1000,
		}
	);
};

export default useListEverbridgeVCCCategories;
