import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const getDefaultEverbridgeIntegrationDocument = graphql(/* GraphQL */ `
	query getDefaultEverbridgeIntegration {
		getDefaultEverbridgeIntegration {
			url
		}
	}
`);

const getDefaultEverbridgeIntegration = (userId: string | undefined) =>
	client(userId)
		.request(getDefaultEverbridgeIntegrationDocument)
		.then((res) => res.getDefaultEverbridgeIntegration);

const useGetDefaultEverbridgeIntegration = () => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id, "defaultEverbridgeIntegration"],
		() => getDefaultEverbridgeIntegration(userId),
		{
			cacheTime: 24 * 60 * 60 * 1000,
			enabled: !!userId,
			onError: (err) => handleError(err),
			staleTime: 24 * 60 * 60 * 1000,
		}
	);
};

export default useGetDefaultEverbridgeIntegration;
