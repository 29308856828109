import { useInfiniteQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { streamIdPlaceholder } from "common/apiDefinitions";
import { graphql } from "gql/gql";
import { ListAlertsParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listAlertsDocument = graphql(/* GraphQL */ `
	query newListAlerts($params: ListAlertsParams!) {
		newListAlerts(params: $params) {
			...alertFields
			# TODO: https://samdesk.atlassian.net/browse/DEV-5029 see if we can just get a length value for cases like this (we just need an array to get the length)
		}
	}
`);

interface Args {
	pageParam: null | string | undefined;
	params: ListAlertsParams;
	userId?: string;
}

const listAlerts = async ({ pageParam, userId, params }: Args) => {
	const updatedParams = { ...params };
	updatedParams.before =
		pageParam ? new Date(pageParam).toISOString() : new Date().toISOString();
	updatedParams.stream_ids = params.stream_ids.filter(
		(_id) => _id !== streamIdPlaceholder
	);

	const res = await client(userId).request(listAlertsDocument, {
		params: updatedParams,
	});
	return res.newListAlerts;
};

const useListAlerts = (params: ListAlertsParams) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const alertsQueryKey = listAlertsQueryKey(userId, _id, params.stream_ids);

	return useInfiniteQuery(
		alertsQueryKey,
		({ pageParam }: { pageParam?: null | string | undefined }) =>
			listAlerts({ pageParam, params, userId }),
		{
			enabled: !!userId && params.stream_ids.length > 0,
			getNextPageParam: (lastPage): number | undefined => {
				if (lastPage.length === 0) return;

				/**
				 * Get 'created' date from the last (oldest) alert that's been loaded
				 * We don't need to guard against invalid dates here because the backend does
				 * that for us
				 * NOTE: we can't use Array.at() because we support Firefox 80+
				 */
				const lastWithCreated = lastPage.findLast(
					(el): el is Extract<typeof el, { created: unknown }> =>
						el && "created" in el
				);

				if (!lastWithCreated) return 0;

				const oldestTimeStamp = new Date(lastWithCreated.created).getTime();

				return oldestTimeStamp;
			},
			onError: (error) => handleError(error),
			refetchInterval: 30 * 1000,
			refetchIntervalInBackground: true,
			refetchOnWindowFocus: true,
		}
	);
};

export default useListAlerts;

export const listAlertsQueryKey = (
	userId: string | undefined,
	_id: string | undefined,
	streamIds: string[]
) => {
	return ["user", userId, "account", _id, "alerts", streamIds];
};
