import { useRollbar } from "@rollbar/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { earliestPossibleQueryDate } from "common/apiDefinitions";
import { graphql } from "gql/gql";
import { SearchIncidentsParams } from "gql/graphql";
import getOldestDetectedDate from "utils/getOldestDetectedDate";
import logErrors from "utils/logErrors";
import searchIncidentsCanQuery from "utils/searchIncidentsCanQuery";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const searchIncidentsDocument = graphql(/* GraphQL */ `
	query searchIncidents($params: SearchIncidentsParams!) {
		searchIncidents(params: $params) {
			highlight {
				source
				text
			}
			incident {
				_id
				detected_date
				headline {
					source
					text
					type
				}
				severity
				types
				wheres {
					compact_display
				}
			}
		}
	}
`);

interface Args {
	pageParam: null | string | undefined;
	params: SearchIncidentsParams;
	userId?: string;
}

const searchIncidents = ({ pageParam, params, userId }: Args) => {
	const updatedParams = { ...params };

	const before = params.before ?? new Date().toISOString();
	updatedParams.before = pageParam ? new Date(pageParam).toISOString() : before;

	return client(userId)
		.request(searchIncidentsDocument, { params: updatedParams })
		.then((res) => res.searchIncidents);
};

const useSearchIncidents = (params: SearchIncidentsParams) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();
	const rollbar = useRollbar();

	return useInfiniteQuery(
		["user", userId, "account", _id, "searchIncidents", params],
		({ pageParam }: { pageParam?: null | string | undefined }) =>
			searchIncidents({ pageParam, params, userId }),
		{
			cacheTime: 0,
			enabled: !!_id && searchIncidentsCanQuery(params),
			getNextPageParam: (lastPage): number | undefined => {
				if (lastPage.length === 0) return;

				const oldestDetectedDate = getOldestDetectedDate(lastPage);
				if (!oldestDetectedDate) {
					handleError("We are unable to load more incidents at this time");
					logErrors(
						`No detected_date found in latest search results: ${JSON.stringify(
							lastPage
						)}`,
						"src/api/queries/useSearchIncidents.tsx"
					);

					/** log to Rollbar */
					rollbar.error(
						new Error(
							`No detected_date found in latest search results: ${JSON.stringify(
								lastPage
							)}`
						)
					);

					return;
				}

				// can't query any further back in time
				if (oldestDetectedDate < new Date(earliestPossibleQueryDate)) return;

				const oldestTimeStamp = oldestDetectedDate.getTime();
				return oldestTimeStamp;
			},
			onError: (error) => handleError(error),
		}
	);
};

export default useSearchIncidents;
