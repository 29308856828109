import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListInputStreamCategoriesParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listInputStreamCategoriesDocument = graphql(/* GraphQL */ `
	query listInputStreamCategories($params: ListInputStreamCategoriesParams!) {
		listInputStreamCategories(params: $params) {
			_id
			children {
				_id
				category_id
				name
				description
				tooltip
			}
			color
			created
			description
			name
			type
		}
	}
`);

interface Args {
	params: ListInputStreamCategoriesParams;
	userId?: string;
}

const listInputStreamCategories = ({ params, userId }: Args) =>
	client(userId)
		.request(listInputStreamCategoriesDocument, { params })
		.then((res) => res.listInputStreamCategories);

const useListInputStreamCategories = (
	params: ListInputStreamCategoriesParams
) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	return useQuery(
		["user", userId, "account", _id, "streamCategories", params._ids],
		() => listInputStreamCategories({ params, userId }),
		{
			cacheTime: 24 * 60 * 60 * 1000,
			enabled: !!userId,
			onError: (error) => handleError(error),
			staleTime: 24 * 60 * 60 * 1000,
		}
	);
};

export default useListInputStreamCategories;
