import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetIncidentSummarySessionParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getIncidentSummarySessionDocument = graphql(/* GraphQL */ `
	query getIncidentSummarySession($params: GetIncidentSummarySessionParams!) {
		getIncidentSummarySession(params: $params) {
			error {
				code
				message
			}
			incident_summary_session {
				incident_id
				gid
				text_plain
				text_markdown
				followup_questions {
					gid
					label
					type
				}
			}
		}
	}
`);

interface Args {
	params: GetIncidentSummarySessionParams;
	userId?: string;
}

const getIncidentSummarySession = ({ params, userId }: Args) => {
	return client(userId)
		.request(getIncidentSummarySessionDocument, { params })
		.then((res) => res.getIncidentSummarySession);
};

const useGetIncidentSummarySession = (
	params: GetIncidentSummarySessionParams
) => {
	const { _id, userId } = useActiveAccount();

	const queryKey = createGetIncidentSummaryQueryKey({
		_id,
		incidentId: params.incident_id,
		userId,
	});

	return useQuery(
		queryKey,
		() => getIncidentSummarySession({ params, userId }),
		{
			cacheTime: 60 * 60 * 1000,
			enabled: !!userId && !!params.incident_id,
			staleTime: 60 * 60 * 1000,
		}
	);
};

export default useGetIncidentSummarySession;

export const createGetIncidentSummaryQueryKey = ({
	_id,
	incidentId,
	userId,
}: {
	_id: string | undefined;
	incidentId: string;
	userId: string | undefined;
}) => {
	return [
		"user",
		userId,
		"account",
		_id,
		"getIncidentSummarySession",
		incidentId,
	];
};
