import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getUserQueryKey } from "api/queries/useGetUser";
import { graphql } from "gql/gql";
import { UpdateUserPreferencesParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const updateUserPreferencesDocument = graphql(/* GraphQL */ `
	mutation updateUserPreferences($params: UpdateUserPreferencesParams!) {
		updateUserPreferences(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: UpdateUserPreferencesParams;
	userId?: string;
}

const updateUserPreferences = ({ userId, params }: Args) =>
	client(userId)
		.request(updateUserPreferencesDocument, { params })
		.then((res) => res.updateUserPreferences);

interface useMutationArgs {
	params: UpdateUserPreferencesParams;
}

const useUpdateUserPreferences = () => {
	const { userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		({ params }: useMutationArgs) => updateUserPreferences({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(getUserQueryKey(), { exact: true });
			},
		}
	);
};

export default useUpdateUserPreferences;
