import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { StreamType } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";
import { listStreams, listStreamsQueryKey } from "./useListStreams";

const getDashboardDocument = graphql(/* GraphQL */ `
	query getDashboard {
		getDashboard {
			tabs {
				__typename
				key
				... on AlertsTab {
					__typename
					alerts_tab_data {
						bottom {
							stream_id
						}
						bottom_sound {
							enabled
							name
						}
						selected_asset_sets {
							asset_set_id
							include
						}
						name
						top {
							stream_id
						}
						top_sound {
							enabled
							name
						}
						map_width
						pane_height
					}
				}
				... on IncidentTab {
					__typename
					incident_tab_data {
						incident_id
					}
				}
			}
		}
	}
`);

interface Args {
	accountId?: string;
	queryClient: QueryClient;
	userId?: string;
}

interface StreamId {
	stream_id: string;
}

const getDashboard = async ({ queryClient, userId, accountId }: Args) => {
	const streams = await queryClient.fetchQuery(
		listStreamsQueryKey(userId, accountId),
		() =>
			listStreams({
				params: { types: [StreamType.STANDARD, StreamType.WEB_ALERTS] },
				userId,
			})
	);
	const streamIds = streams?.map((stream) => stream._id);

	return client(userId)
		.request(getDashboardDocument)
		.then((res) => {
			/**
			 * filtering out any stream ids selected in a tab that aren't on a list of streams
			 * the backend cleans these up after a stream is deleted
			 * but it's a process that could be delayed
			 * so we want to make sure not to show any selected tabs that were deleted
			 */
			res.getDashboard.tabs.forEach((tab) => {
				if (tab.__typename === "AlertsTab" && streamIds) {
					const updatedTop: StreamId[] = [];

					tab.alerts_tab_data.top.forEach((stream) => {
						if (streamIds.includes(stream.stream_id)) updatedTop.push(stream);
					});

					const updatedBottom: StreamId[] = [];

					tab.alerts_tab_data.bottom.forEach((stream) => {
						if (streamIds.includes(stream.stream_id)) {
							updatedBottom.push(stream);
						}
					});

					tab.alerts_tab_data.top = updatedTop;
					tab.alerts_tab_data.bottom = updatedBottom;

					return tab;
				} else {
					return tab;
				}
			});

			/** returning updated getDashboard data */
			return res.getDashboard;
		});
};

const useGetDashboard = () => {
	const queryClient = useQueryClient();
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const dashboardQueryKey = getDashboardQueryKey(userId, _id);

	return useQuery(
		dashboardQueryKey,
		() => getDashboard({ accountId: _id, queryClient, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId && window.location.pathname !== "/unauthorized",
			onError: (error) => handleError(error),
			refetchOnReconnect: "always",
			/** to keep the dashboard data up to date if switching between tab and/or browsers with the same account */
			refetchOnWindowFocus: "always",
			staleTime: 5 * 60 * 1000,
			useErrorBoundary: true,
		}
	);
};

export default useGetDashboard;

export const getDashboardQueryKey = (
	userId: string | undefined,
	_id: string | undefined
) => {
	return ["user", userId, "account", _id, "dashboard"];
};
