import {
	OperationVariables,
	UseFragmentOptions,
	useFragment,
} from "@apollo/client";
import { FragmentDefinitionNode } from "graphql";
import { isEmpty } from "lodash";

/**
 * Helper for Apollo's useFragment that infers fragmentName and gid.
 *
 * Similar to manually calling:
 * ```
 * const { data, complete } = useFragment({
 *   fragment,
 *   fragmentName: "IncidentResponseNotification",
 *   from: { __typename: "PublicIncidentResponseNotification", gid },
 * });
 * ```
 */
export function useFragmentGID<TData, TVars = OperationVariables>({
	gid,
	...options
}: Omit<UseFragmentOptions<TData, TVars>, "from"> & { gid?: GID }) {
	// If there is more than one fragment in the document,
	// assume we want the first one
	const firstFragmentName = options.fragment.definitions.find(
		(definition): definition is FragmentDefinitionNode =>
			definition.kind === "FragmentDefinition"
	)?.name.value;

	const result = useFragment({
		fragmentName: firstFragmentName,
		from: { __typename: "FromGID", gid },
		...options,
	});

	// For some reason, Apollo doesn't set complete to false when the data is empty
	result.complete = result.complete && !isEmpty(result.data);

	return result;
}
