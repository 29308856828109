import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { createListEverbridgeIntegrationsQueryKey } from "api/queries/useListEverbridgeIntegrations";
import { graphql } from "gql/gql";
import { DeleteEverbridgeIntegrationParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const deleteEverbridgeIntegrationDocument = graphql(/* GraphQL */ `
	mutation deleteEverbridgeIntegration(
		$params: DeleteEverbridgeIntegrationParams!
	) {
		deleteEverbridgeIntegration(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: DeleteEverbridgeIntegrationParams;
	userId?: string;
}

const deleteEverbridgeIntegration = ({ userId, params }: Args) =>
	client(userId)
		.request(deleteEverbridgeIntegrationDocument, { params })
		.then((res) => res.deleteEverbridgeIntegration);

const useDeleteEverbridgeIntegration = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: DeleteEverbridgeIntegrationParams) =>
			deleteEverbridgeIntegration({ params, userId }),
		{
			onSettled: () => {
				const listEverbridgeIntegrationsQueryKey =
					createListEverbridgeIntegrationsQueryKey(userId, _id);
				queryClient.invalidateQueries(listEverbridgeIntegrationsQueryKey);
			},
		}
	);
};

export default useDeleteEverbridgeIntegration;
