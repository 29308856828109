import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { DeleteSlackWebhookParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const deleteSlackWebhookDocument = graphql(/* GraphQL */ `
	mutation deleteSlackWebhook($params: DeleteSlackWebhookParams!) {
		deleteSlackWebhook(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: DeleteSlackWebhookParams;
	userId?: string;
}

const deleteSlackWebhook = ({ userId, params }: Args) =>
	client(userId)
		.request(deleteSlackWebhookDocument, { params })
		.then((res) => res.deleteSlackWebhook);

const useDeleteSlackWebhook = () => {
	const { userId, _id } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: DeleteSlackWebhookParams) =>
			deleteSlackWebhook({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"slackWebhooks",
				]);
			},
		}
	);
};

export default useDeleteSlackWebhook;
