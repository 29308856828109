import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateProximityAssetSetParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useNavigateWithAccount from "utils/useNavigateWithAccount";
import useSnackbar from "utils/useSnackbar";

const createProximityAssetSetDocument = graphql(/* GraphQL */ `
	mutation createProximityAssetSet($params: CreateProximityAssetSetParams!) {
		createProximityAssetSet(params: $params) {
			error {
				code
				message
			}
			asset_set {
				_id
			}
			success
		}
	}
`);

interface Args {
	params: CreateProximityAssetSetParams;
	userId?: string;
}

const createProximityAssetSet = ({ params, userId }: Args) =>
	client(userId)
		.request(createProximityAssetSetDocument, { params })
		.then((res) => res.createProximityAssetSet);

const useCreateProximityAssetSet = () => {
	const { userId, _id } = useActiveAccount();
	const { handleError, handleSuccess } = useSnackbar();
	const navigate = useNavigateWithAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateProximityAssetSetParams) =>
			createProximityAssetSet({ params, userId }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(
					["user", userId, "account", _id, "proximityAssetSets"],
					{ exact: true }
				);
			},
			onSuccess: (data) => {
				if (data.error) {
					handleError(data.error);
				} else if (data.success) {
					navigate(
						`settings/account/assets/collections/${data?.asset_set?._id}`
					);
					handleSuccess("Asset collection has been created.");
				}
			},
		}
	);
};

export default useCreateProximityAssetSet;
