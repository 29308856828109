import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SignupParams } from "gql/graphql";

const signupDocument = graphql(/* GraphQL */ `
	mutation signup($params: SignupParams!) {
		signup(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

const signup = (params: SignupParams) =>
	client()
		.request(signupDocument, { params })
		.then((res) => res.signup);

const useSignup = () => {
	const queryClient = useQueryClient();

	return useMutation((params: SignupParams) => signup(params), {
		onSuccess: (data) => {
			// TODO DEV-4943: we will want to pre-populate the ['person'] cache to speed up the auth flow
			if (data.success) queryClient.invalidateQueries(["person"]);
		},
	});
};

export default useSignup;
