import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetIncidentParams, getIncidentNearbyAssetsQuery } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

export type NearbyAsset = NonNullable<
	getIncidentNearbyAssetsQuery["getIncident"]
>["nearby_assets"][number];

const getIncidentNearbyAssetsDocument = graphql(/* GraphQL */ `
	query getIncidentNearbyAssets($params: GetIncidentParams!) {
		getIncident(params: $params) {
			...nearbyAssetsFields
		}
	}
`);

interface Args {
	params: GetIncidentParams;
	userId?: string;
}

const getIncidentNearbyAssets = ({ params, userId }: Args) => {
	return client(userId)
		.request(getIncidentNearbyAssetsDocument, { params })
		.then((res) => res.getIncident?.nearby_assets);
};

export function useGetIncidentNearbyAssets(params: GetIncidentParams) {
	const { _id: accountId, userId } = useActiveAccount();

	return useQuery(
		[
			"user",
			userId,
			"account",
			accountId,
			"incident",
			params._id,
			"nearbyAssets",
		],
		() => getIncidentNearbyAssets({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId && !!params._id,
			staleTime: 5 * 60 * 1000,
		}
	);
}
