import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListStreamsParams, StreamType } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listStreamsDocument = graphql(/* GraphQL */ `
	query listStreams($params: ListStreamsParams!) {
		listStreams(params: $params) {
			...streamFields
		}
	}
`);

interface Args {
	params: ListStreamsParams;
	userId?: string;
}

export const listStreams = ({ params, userId }: Args) =>
	client(userId)
		.request(listStreamsDocument, { params })
		.then((res) => res.listStreams);

const useListStreams = (params: ListStreamsParams = {}) => {
	const { _id, userId } = useActiveAccount();
	const { handleError } = useSnackbar();

	const adjustedParams: ListStreamsParams = {
		...params,
		types: params.types ?? [StreamType.STANDARD, StreamType.WEB_ALERTS],
	};

	const streamsQueryKey = listStreamsQueryKey(userId, _id, adjustedParams);

	return useQuery(
		streamsQueryKey,
		() => listStreams({ params: adjustedParams, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId,
			onError: (error) => handleError(error),
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useListStreams;

export const listStreamsQueryKey = (
	userId: string | undefined,
	_id: string | undefined,
	params: ListStreamsParams = {}
) => {
	return ["user", userId, "account", _id, "streams", params];
};
