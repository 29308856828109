import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateAPIKeyParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createAPIKeyDocument = graphql(/* GraphQL */ `
	mutation createAPIKey($params: CreateAPIKeyParams!) {
		createAPIKey(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: CreateAPIKeyParams;
	userId?: string;
}
const createAPIKey = ({ userId, params }: Args) =>
	client(userId)
		.request(createAPIKeyDocument, { params })
		.then((res) => res.createAPIKey);

const useCreateAPIKey = () => {
	const { _id, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateAPIKeyParams) => createAPIKey({ userId, params }),
		{
			onSettled: () => {
				queryClient.invalidateQueries(["user", userId, "account", _id], {
					exact: true,
				});
			},
		}
	);
};

export default useCreateAPIKey;
