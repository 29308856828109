import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { getDashboardQueryKey } from "api/queries/useGetDashboard";
import { Dashboard } from "autoGenSubTypes";
import { graphql } from "gql/gql";
import { DeleteDashboardTabParams } from "gql/graphql";
import deepCopy from "utils/deepCopy";
import { useActiveAccount } from "utils/useActiveAccount";

const deleteDashboardTabDocument = graphql(/* GraphQL */ `
	mutation deleteDashboardTab($params: DeleteDashboardTabParams!) {
		deleteDashboardTab(params: $params) {
			error {
				code
				message
			}
			success
			tab {
				__typename
				... on IncidentTab {
					__typename
					incident_tab_data {
						incident_id
					}
				}
			}
		}
	}
`);

interface Args {
	params: DeleteDashboardTabParams;
	userId?: string;
}

const deleteDashboardTab = ({ userId, params }: Args) =>
	client(userId)
		.request(deleteDashboardTabDocument, { params })
		.then((res) => res.deleteDashboardTab);

const useDeleteDashboardTab = () => {
	const queryClient = useQueryClient();
	const { _id, userId } = useActiveAccount();

	const dashboardQueryKey = getDashboardQueryKey(userId, _id);

	return useMutation(
		({ params }: Args) => deleteDashboardTab({ params, userId }),
		{
			onMutate: async (variables) => {
				/** optimistic update for deleting a tab */
				await queryClient.cancelQueries(dashboardQueryKey, { exact: true });

				const prevDashboard = queryClient.getQueryData(dashboardQueryKey, {
					exact: true,
				});

				queryClient.setQueryData<Dashboard>(dashboardQueryKey, (oldData) => {
					/** need check so typescript knows that there will be dashboard data */
					if (oldData === undefined || !oldData?.tabs) return oldData;

					const updatedDashboard = deepCopy(oldData);
					const updatedTabs = updatedDashboard.tabs.filter(
						(tab) => tab.key !== variables.params.key
					);

					updatedDashboard.tabs = updatedTabs;

					return updatedDashboard;
				});

				return { prevDashboard };
			},
			onSettled: (data, error, variables, context) => {
				/** on error, set cache back to what it was before the optimistic update */
				if ((data?.error || error) && context?.prevDashboard) {
					queryClient.setQueryData(dashboardQueryKey, context.prevDashboard);
				}

				queryClient.invalidateQueries(dashboardQueryKey, { exact: true });
			},
		}
	);
};

export default useDeleteDashboardTab;
