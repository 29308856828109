import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";

const getTravellerDataDocument = graphql(/* GraphQL */ `
	query getTravellerData {
		getTravellerData {
			all_travellers {
				admin_1
				country
				email
				end_date
				locality
				name
				start_date
				status
			}
			region_data {
				active_count
				country
				pending_count
			}
			time
		}
	}
`);

interface Args {
	userId?: string;
}

const getTravellerData = ({ userId }: Args) =>
	client(userId)
		.request(getTravellerDataDocument)
		.then((res) => res.getTravellerData);

const useGetTravellerData = () => {
	const { userId, _id } = useActiveAccount();

	return useQuery(
		["user", userId, "account", _id, "getTravellerData"],
		() => getTravellerData({ userId }),
		{
			enabled: !!userId,
		}
	);
};

export default useGetTravellerData;
