import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { useActiveAccount } from "utils/useActiveAccount";

const listAccountMembersDocument = graphql(/*Graphql*/ `
	query listAccountMembers {
		listAccountMembers {
			pending {
				_id
				account {
					name
				}
				email
				role
			}
			active {
				_id
				account_id
				role
				user {
					_id
					email
					name
					last_activity
				}
			}
		}
	}
`);

const listAccountMembers = (userId: string | undefined) =>
	client(userId)
		.request(listAccountMembersDocument)
		.then((res) => res.listAccountMembers);

const useListAccountMembers = () => {
	const { _id, userId } = useActiveAccount();

	const listAccountMembersKey = listAccountMembersQueryKey(userId, _id);

	return useQuery(listAccountMembersKey, () => listAccountMembers(userId), {
		cacheTime: 5 * 60 * 1000,
		enabled: !!userId,
		staleTime: 5 * 60 * 1000,
	});
};

export default useListAccountMembers;

export const listAccountMembersQueryKey = (
	userId: string | undefined,
	_id: string | undefined
) => {
	return ["user", userId, "account", _id, "listAccountMembers"];
};
