import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetResetPasswordRequestParams } from "gql/graphql";
import useSnackbar from "utils/useSnackbar";

const getResetPasswordRequestDocument = graphql(/* GraphQL */ `
	query getResetPasswordRequest($params: GetResetPasswordRequestParams!) {
		getResetPasswordRequest(params: $params) {
			code
			account_password_policy {
				min_length
				require_lowercase
				require_number
				require_special
				require_uppercase
			}
		}
	}
`);

interface Args {
	params: GetResetPasswordRequestParams;
}

const getResetPasswordRequest = ({ params }: Args) => {
	return client()
		.request(getResetPasswordRequestDocument, { params })
		.then((res) => res.getResetPasswordRequest);
};

const useGetResetPasswordRequest = (params: GetResetPasswordRequestParams) => {
	const { handleError } = useSnackbar();

	return useQuery(
		["reset_password", params.code],
		() => getResetPasswordRequest({ params }),
		{
			enabled: !!params.code,
			onError: (error) => handleError(error),
		}
	);
};

export default useGetResetPasswordRequest;
