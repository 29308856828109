import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { GetWeatherParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const getWeatherDocument = graphql(/* GraphQL */ `
	query getWeather($params: GetWeatherParams!) {
		getWeather(params: $params) {
			icon
			long_description
			short_description
			temp
		}
	}
`);

interface Args {
	params: GetWeatherParams;
	userId?: string;
}

const getWeather = async ({ userId, params }: Args) => {
	return client(userId)
		.request(getWeatherDocument, { params })
		.then((res) => res.getWeather);
};

const useGetWeather = (params: GetWeatherParams) => {
	const { userId, _id } = useActiveAccount();

	const validLatLng =
		params.lat >= -90 &&
		params.lat <= 90 &&
		params.lng >= -180 &&
		params.lng <= 180;

	return useQuery(
		["user", userId, "account", _id, "weather", params],
		() => getWeather({ params, userId }),
		{
			cacheTime: 5 * 60 * 1000,
			enabled: !!userId && validLatLng,
			staleTime: 5 * 60 * 1000,
		}
	);
};

export default useGetWeather;
