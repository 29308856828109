import { useQuery } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { ListSlackWorkspacesParams } from "gql/graphql";
import { useHasFeature } from "ui-library/HasFeature";
import { useActiveAccount } from "utils/useActiveAccount";
import useSnackbar from "utils/useSnackbar";

const listSlackWorkspacesDocument = graphql(/* GraphQL */ `
	query listSlackWorkspaces($params: ListSlackWorkspacesParams!) {
		listSlackWorkspaces(params: $params) {
			...slackWorkspaceFields
		}
	}
`);

interface Args {
	params: ListSlackWorkspacesParams;
	userId?: string;
}

const listSlackWorkspaces = ({ userId, params }: Args) =>
	client(userId)
		.request(listSlackWorkspacesDocument, { params })
		.then((res) => res.listSlackWorkspaces);

const useListSlackWorkspaces = (params: ListSlackWorkspacesParams = {}) => {
	const { userId, _id } = useActiveAccount();
	const { handleError } = useSnackbar();
	const hasSlackWorkspaceFeature = useHasFeature("slack_workspace");

	return useQuery(
		["user", userId, "account", _id, "listSlackWorkspaces"],
		() => listSlackWorkspaces({ params, userId }),
		{
			enabled: !!userId && hasSlackWorkspaceFeature,
			onError: (error) => handleError(error),
		}
	);
};

export default useListSlackWorkspaces;
