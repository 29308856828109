import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateOutgoingWebhookParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createOutgoingWebhookDocument = graphql(/* GraphQL */ `
	mutation createOutgoingWebhook($params: CreateOutgoingWebhookParams!) {
		createOutgoingWebhook(params: $params) {
			outgoing_webhook {
				_id
				account_id
				disabled
				url
				header_key
				header_value
				created
				updated
				user_id
				name
			}
			success
			error {
				code
				message
			}
		}
	}
`);

interface Args {
	params: CreateOutgoingWebhookParams;
	userId?: string;
}

const createOutgoingWebhook = ({ params, userId }: Args) =>
	client(userId)
		.request(createOutgoingWebhookDocument, { params })
		.then((res) => res.createOutgoingWebhook);

const useCreateOutgoingWebhook = () => {
	const { userId, _id } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateOutgoingWebhookParams) =>
			createOutgoingWebhook({ params, userId }),
		{
			onSettled: () =>
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					_id,
					"outgoingWebhooks",
				]),
		}
	);
};

export default useCreateOutgoingWebhook;
