import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SigninParams } from "gql/graphql";

const signinDocument = graphql(/* GraphQL */ `
	mutation signin($params: SigninParams!) {
		signin(params: $params) {
			success
			person {
				...personFields
			}
			error {
				code
				message
			}
		}
	}
`);

interface Args {
	params: SigninParams;
}

function signin({ params }: Args) {
	return client()
		.request(signinDocument, { params })
		.then((res) => res.signin);
}

export function useSignin() {
	const queryClient = useQueryClient();

	return useMutation(({ params }: Args) => signin({ params }), {
		onSuccess: (data) => {
			// TODO DEV-4943: we will want to pre-populate the ['person'] cache to speed up the auth flow
			if (data.success) queryClient.invalidateQueries(["person"]);
		},
	});
}
