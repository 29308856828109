import { useMutation } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { SubmitResetPasswordRequestParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const submitResetPasswordRequestDocument = graphql(/* GraphQL */ `
	mutation submitResetPasswordRequest(
		$params: SubmitResetPasswordRequestParams!
	) {
		submitResetPasswordRequest(params: $params) {
			error {
				code
				message
			}
			success
		}
	}
`);

interface Args {
	params: SubmitResetPasswordRequestParams;
	userId?: string;
}

const submitResetPasswordRequest = ({ params, userId }: Args) =>
	client(userId)
		.request(submitResetPasswordRequestDocument, { params })
		.then((res) => res.submitResetPasswordRequest);

const useSubmitResetPasswordRequest = () => {
	const { userId } = useActiveAccount();

	return useMutation((params: SubmitResetPasswordRequestParams) =>
		submitResetPasswordRequest({ params, userId })
	);
};

export default useSubmitResetPasswordRequest;
