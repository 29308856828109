import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "api/graphql/getClient";
import { graphql } from "gql/gql";
import { CreateStandardStreamParams } from "gql/graphql";
import { useActiveAccount } from "utils/useActiveAccount";

const createStandardStreamDocument = graphql(/* GraphQL */ `
	mutation CreateStandardStream($params: CreateStandardStreamParams!) {
		createStandardStream(params: $params) {
			error {
				code
				message
			}
			stream {
				_id
			}
			success
		}
	}
`);

interface Args {
	params: CreateStandardStreamParams;
	userId?: string;
}

const createStandardStream = ({ params, userId }: Args) =>
	client(userId)
		.request(createStandardStreamDocument, { params })
		.then((res) => res.createStandardStream);

export function useCreateStandardStream() {
	const { _id: accountId, userId } = useActiveAccount();
	const queryClient = useQueryClient();

	return useMutation(
		(params: CreateStandardStreamParams) =>
			createStandardStream({ params, userId }),
		{
			onSettled: () => {
				/** invalidate every streams query in case it includes the new stream */
				queryClient.invalidateQueries([
					"user",
					userId,
					"account",
					accountId,
					"streams",
				]);
			},
		}
	);
}
